import React from 'react';
import './sections.css';
import common from './img/3.3-common.png';
import dealers from './img/3.3-dealers.png';

class MT5Routing extends React.Component {
    render = () => (
        <div>            
            <h2>3.0 MT5 Server Configuration</h2>
            <h2>3.3 Routing</h2>
            <ul>
                <li>
                    <p><b>Routing - Common</b></p>
                    <p>Routing should be configured as per below:</p>
                    <img src={common}
                        alt="not found"
                        style={{
                            width: '50vw',
                            maxWidth: '700px',
                            height:'auto',                                                        
                        }}
                    ></img>
                </li>
                <li>
                    <p><b>Routing - Dealers</b></p>
                    <p>Routing should be configured as per below:</p>
                    <img src={dealers}
                        alt="not found"
                        style={{
                            width: '50vw',
                            maxWidth: '700px',
                            height:'auto',                                                        
                        }}
                    ></img>
                </li>
            </ul>
        </div>
    )
}

export default MT5Routing;