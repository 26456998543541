import React from 'react';
import './technicalGuide.css';

import {
  Purpose,
  Onboarding,
  MT5Groups,
  MT5Gateways,
  MT5Routing,
  MT5Symbols,
  OZMargins,
  OZMakerAdapter,
  OZSymbols,
  OZHubApp,
  OrderHandling,
  Downloads,
} from './sections'

import SideNav, { NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';

// Be sure to include styles at some point, probably during your bootstraping
import '@trendmicro/react-sidenav/dist/react-sidenav.css';


class TechnicalGuide extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      section: "",
      expanded: true,
  }

  }
  render = () => (
    <div>
      <div className="sideBar">
        <SideNav  className="sideBarColor" expanded={this.state.expanded} onToggle={() => this.setState({expanded: !this.state.expanded})}  >
          <SideNav.Toggle />
        <SideNav.Nav defaultSelected="purpose" onSelect={(eventKey) => this.changeSection(eventKey)} >
            
            {/* Purpose */}
            <NavItem eventKey="purpose" onClick={() => {this.changeSection("purpose")}}>
                <NavIcon>
                    <i className="fa fa-fw fa-home" style={{ fontSize: '1.75em', verticalAlign: 'middle' }} />
                </NavIcon>
                <NavText>
                    1.0 Purpose
                </NavText>
            </NavItem>

            {/* On Boarding Steps */}            
            <NavItem eventKey="onboarding" onClick={() => {this.changeSection("onboarding")}}>
            <NavIcon>
                    <i className="fa fa-file-signature" style={{ fontSize: '1.75em', verticalAlign: 'middle' }} />
                </NavIcon>
                <NavText>
                    2.0 Onboarding
                </NavText>
            </NavItem>

            {/* MT5 Server Config */}
            <NavItem eventKey="mt5-expand">
                <NavIcon>
                    <i className="fa fa-server" style={{ fontSize: '1.75em', verticalAlign: 'middle' }} />
                </NavIcon>
                <NavText>
                    3.0 MT5 Server
                </NavText>
                <NavItem eventKey="mt5-groups">
                    <NavText>
                        3.1 Groups
                    </NavText>
                </NavItem>
                <NavItem eventKey="mt5-gateways">
                    <NavText>
                        3.2 Gateways
                    </NavText>
                </NavItem>
                <NavItem eventKey="mt5-routing">
                    <NavText>
                        3.3 Routing
                    </NavText>
                </NavItem>
                <NavItem eventKey="mt5-symbols">
                    <NavText>
                        3.4 Symbols
                    </NavText>
                </NavItem>
            </NavItem>

            {/* oneZero Hub Config */}
            <NavItem eventKey="oz-margins" onClick={() => {this.changeSection("oz-margins")}}>
                <NavIcon>
                    <i className="fa fa-warehouse" style={{ fontSize: '1.75em', verticalAlign: 'middle' }} />
                </NavIcon>
                <NavText>                  
                    4.0 oneZero Hub
                </NavText>
                {/* <NavItem eventKey="oz-margins">
                    <NavText>
                        Margins
                    </NavText>
                </NavItem> */}
                <NavItem eventKey="oz-maker-adapter">
                    <NavText>
                        4.1 26 Degrees DMA Maker Adapter
                    </NavText>
                </NavItem>
                <NavItem eventKey="oz-symbols">
                    <NavText>
                        4.2 Symbol Settings
                    </NavText>
                </NavItem>
                <NavItem eventKey="oz-hub-app">
                    <NavText>
                        4.3 Hub App
                    </NavText>
                </NavItem>
            </NavItem>

            {/* Accepted Order Types and Order Handling */}            
            <NavItem eventKey="order-handling" onClick={() => {this.changeSection("order-handling")}}>
            <NavIcon>
                    <i className="fa fa-funnel-dollar" style={{ fontSize: '1.75em', verticalAlign: 'middle' }} />
                </NavIcon>
                <NavText>
                    5.0 Order Handling
                </NavText>
            </NavItem>

            {/* MT5-Specific Downloads */}
            <NavItem eventKey="downloads" onClick={() => {this.changeSection("downloads")}}>
            <NavIcon>
                    <i className="fas fa-cloud-download-alt" style={{ fontSize: '1.75em', verticalAlign: 'middle' }} />
                </NavIcon>
                <NavText>
                    6.0 Downloads
                </NavText>
            </NavItem>

          </SideNav.Nav>
        </SideNav>
      </div>
<div className="guide-container">
<div className="main-help"><h2>TECHNICAL REFERENCE GUIDE</h2></div>

<div className="guide-body">
{this.renderGuide()}


</div>
</div>
</div>
  )

changeSection = (sectionName) => {
  this.setState({
    section: sectionName
  })
}


renderGuide = () => {
  switch(this.state.section) {
    case "purpose": return <Purpose />;
    case "onboarding": return <Onboarding />;
    case "mt5-groups": return <MT5Groups />;
    case "mt5-gateways": return <MT5Gateways />;
    case "mt5-routing": return <MT5Routing />;
    case "mt5-symbols": return <MT5Symbols />;
    case "oz-margins": return <OZMargins />;
    case "oz-maker-adapter": return <OZMakerAdapter />;
    case "oz-symbols": return <OZSymbols />;
    case "oz-hub-app": return <OZHubApp />;
    case "order-handling": return <OrderHandling />;
    case "downloads": return <Downloads token={this.props.token} />;
    default: return <Purpose />;
  }
}

componentDidMount() {
    if (window.location.href.includes("#")) {
      var sec = window.location.href.split("#")[1]
      this.changeSection(sec)
    } else {
      this.changeSection("overview")
    }
}
}

export default TechnicalGuide;