import React from 'react';

import './sections.css';

import sessionTimes from './img/4.1-session-times.png';
import executionSettings from './img/4.1-execution-settings.png';
import symbols1 from './img/4.1-symbols-1.png';
import symbols2 from './img/4.1-symbols-2.png';

class OZMakerAdapter extends React.Component {
    render = () => (
        <div>            
            <h2>4.0 oneZero Hub Configuration</h2>
            <h2>4.1 Settings - 26 Degrees DMA Maker Adapter</h2>
            <ul>
                <li>
                    <p><b>Session Times</b></p>
                    <p>- Maker ID: Invast_DMA_Maker</p>
                    <p>- Timezone: America / New York</p>
                    <p>- Time Ranges: As per the below</p>
                    <img src={sessionTimes}
                        alt="not found"
                        style={{
                            width: '70vw',
                            maxWidth: '1000px',
                            height:'auto',                                                        
                        }}
                    ></img>
                </li>
                <li>
                    <p><b>Execution Method Settings</b> - As per the below - <span className={'red-note'}>Important</span></p>                    
                    <img src={executionSettings}
                        alt="not found"
                        style={{
                            width: '70vw',
                            maxWidth: '1000px',
                            height:'auto',                                                        
                        }}
                    ></img>
                </li>
                <li>
                    <p><b>Symbol Attributes</b></p>
                    <p>- Volume Mode: As Quoted</p>
                    <p>- STP Liquidity Type: Full Volume Routing</p>
                    <img src={symbols1}
                        alt="not found"
                        style={{
                            width: '70vw',
                            maxWidth: '1000px',
                            height:'auto',                                                        
                        }}
                    ></img>
                </li>
                <li>
                    <p><b>Symbol Attributes</b> - Market depth should be defined with one level as per the below.</p>
                    <img src={symbols2}
                        alt="not found"
                        style={{
                            width: '70vw',
                            maxWidth: '1000px',
                            height:'auto',                                                        
                        }}
                    ></img>
                </li>
            </ul>
        </div>
    )
}

export default OZMakerAdapter;