import React from 'react';
import SideNav, { NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';

// Be sure to include styles at some point, probably during your bootstraping
import '@trendmicro/react-sidenav/dist/react-sidenav.css';
import { FileUpload, UserManagement, EmailNotificationLog } from './sections';
import './admin.css';

class Admin extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      section: "",
      expanded: true,
    }

  }
  render = () => (
    <div>
      <div className="sideBar">
        <SideNav className="sideBarColor" expanded={this.state.expanded} onToggle={() => this.setState({ expanded: !this.state.expanded })}  >
          <SideNav.Toggle />
          <SideNav.Nav defaultSelected="user-management" onSelect={(eventKey) => this.changeSection(eventKey)} >

            {/* User Management Section */}
            <NavItem eventKey="user-management" onClick={() => { this.changeSection("user-management") }}>
              <NavIcon>
                <i className="fa fa-fw fa-home" style={{ fontSize: '1.75em', verticalAlign: 'middle' }} />
              </NavIcon>
              <NavText>
                User Management
                </NavText>
            </NavItem>

            {/* File Upload Section */}
            <NavItem eventKey="file-upload" onClick={() => { this.changeSection("file-upload") }}>
              <NavIcon>
                <i className="fa fa-file-signature" style={{ fontSize: '1.75em', verticalAlign: 'middle' }} />
              </NavIcon>
              <NavText>
                File Upload
                </NavText>
            </NavItem>

            {/* Email log Section */}
            <NavItem eventKey="email-log" onClick={() => { this.changeSection("email-log") }}>
              <NavIcon>
                <i className="fa fa-envelope" style={{ fontSize: '1.75em', verticalAlign: 'middle' }} />
              </NavIcon>
              <NavText>
                Email Log
                </NavText>
            </NavItem>

          </SideNav.Nav>
        </SideNav>
      </div>
      <div className="admin-container">
        <div className="main-help"><h2>ADMINISTRATION</h2></div>

        <div className="guide-body">
          {this.renderSection()}


        </div>
      </div>
    </div>
  )

  changeSection = (sectionName) => {
    this.setState({
      section: sectionName
    })
  }


  renderSection = () => {
    switch (this.state.section) {
      case "user-management": return <UserManagement token={this.props.token} role={this.props.role} />;
      case "file-upload": return <FileUpload token={this.props.token} role={this.props.role} />;
      case "email-log": return <EmailNotificationLog token={this.props.token} role={this.props.role} />;
      default: return <UserManagement token={this.props.token} role={this.props.role} />;
    }
  }

  componentDidMount() {
    if (window.location.href.includes("#")) {
      var sec = window.location.href.split("#")[1]
      this.changeSection(sec)
    } else {
      this.changeSection("overview")
    }
  }
}

export default Admin;