import axios from 'axios';
// comment
// test comment
// Here is a new test comment
// var baseRoute = process.env.REACT_APP_ENDPOINT_DPK + ":" + process.env.REACT_APP_ENDPOINT_DPK_PORT
// var baseRoute = "https://prickle-me-cactus.dev.invast.com.au";
var baseRoute = window.location.origin;
if (baseRoute.includes('localhost')) {
    baseRoute = "https://api.uat.26dgm.com/actions/v1/"
} else if (baseRoute === "https://prickle-me-cactus.dev.26dgm.com") {
    baseRoute = "https://api.dev.26dgm.com/actions/v1/"
} else if (baseRoute === "https://cactus.uat.26dgm.com") {
    baseRoute = "https://api.uat.26dgm.com/actions/v1/"
} else {
    baseRoute = "https://api.26degreesglobalmarkets.com/actions/v1/"
}

export const getActions = (token) => {
    var route = baseRoute + 'list-corporate-actions';
    return axios.get(route, {
        'headers': {
            'Authorization': token
        }
    }).catch(function (error) {
        var status = error.response.status;
        if (status === 401) {
            sessionStorage.clear();
            window.location.href = '/';
        }
    })
        .then(res => {
            return res.data;
        })

}

export const getDetails = (id, token) => {
    var route = baseRoute + 'cact-details/' + id;
    return axios.get(route, {
        'headers': {
            'Authorization': token
        }
    })
        .catch(function (error) {
            if (error.response === undefined) {
                window.location.href = '/sorry';
            }
            var status = error.response.status;
            if (status === 401) {
                sessionStorage.clear();
                window.location.href = '/';
            }
        })
        .then(res => {
            return res.data;
        })

}

export const review = (dataToSend, token) => {
    var route = baseRoute + 'review-cact-details';
    return axios.post(route, dataToSend, {
        'headers': {
            'Authorization': token
        }
    }).catch(function (error) {
        var status = error.response.status;
        if (status === 401) {
            sessionStorage.clear();
            window.location.href = '/';
        }
    })
        .then(res => {
            return res.data;
        })
}

export const getActionsByDateRange = (dateStart, dateEnd, token) => {

    return axios.get(baseRoute + 'list-corporate-actions/' + dateStart + "/" + dateEnd, {
        'headers': {
            'Authorization': token
        }
    }).catch(function (error) {
        if (error.response === undefined) {
            window.location.href = '/sorry';
        }
        var status = error.response.status;
        if (status === 401) {
            sessionStorage.clear();
            window.location.href = '/';
        }
    })
        .then(res => {
            return res.data;
        })

}

export const getActionsBySymbols = (symbols, dateStart, dateEnd, token) => {

    return axios.put(baseRoute + 'filter-corporate-actions/' + dateStart + "/" + dateEnd,
        { "symbols": symbols },
        {
            'headers': {
                'Authorization': token
            }
        }).catch(function (error) {
            if (error.response === undefined) {
                window.location.href = '/sorry';
            }
            var status = error.response.status;
            if (status === 500) {
                window.location.reload()
            }
            if (status === 401) {
                sessionStorage.clear();
                window.location.href = '/';
            }
        })
        .then(res => {
            return res.data;
        })

}

export const getBenchmarks = (dateStart, dateEnd, token) => {
    return axios.get(baseRoute + 'currency-benchmarks/' + dateStart + "/" + dateEnd, {
        'headers': {
            'Authorization': token
        }
    }).catch(function (error) {
        if (error.response === undefined) {
            window.location.href = '/sorry';
        }
        var status = error.response.status;
        if (status === 401) {
            sessionStorage.clear();
            window.location.href = '/';
        }
    })
        .then(res => {
            return res.data;
        })
}

export const getBorrow = (token) => {
    return axios.get(baseRoute + 'additional-borrow', {
        'headers': {
            'Authorization': token
        }
    }).catch(function (error) {
        if (error.response === undefined) {
            window.location.href = '/sorry';
        }
        var status = error.response.status;
        if (status === 401) {
            sessionStorage.clear();
            window.location.href = '/';
        }
    })
        .then(res => {
            return res.data;
        })
}

export const getChangelog = (token) => {
    return axios.get(baseRoute + 'changelog', {
        'headers': {
            'Authorization': token
        }
    }).catch(function (error) {
        if (error.response === undefined) {
            window.location.href = '/sorry';
        }
        var status = error.response.status;
        if (status === 401) {
            sessionStorage.clear();
            window.location.href = '/';
        }
    })
        .then(res => {
            return res.data;
        })
}

export const postChangelogMessage = (body, token) => {
    var route = baseRoute + 'changelog-message';
    return axios.post(route, body, {
        'headers': {
            'Authorization': token
        }
    }).catch(function (error) {
        var status = error.response.status;
        if (status === 401) {
            sessionStorage.clear();
            window.location.href = '/';
        }
    })
        .then(res => {
            return res.data;
        })
}

export const getAvailable = (token) => {
    return axios.get(baseRoute + 'request/available', {
        'headers': {
            'Authorization': token
        }
    }).catch(function (error) {
        if (error.response === undefined) {
            window.location.href = '/sorry';
        }
        var status = error.response.status;
        if (status === 401) {
            sessionStorage.clear();
            window.location.href = '/';
        }
    })
        .then(res => {
            return res.data;
        })
}

export const request = (dataToSend, token) => {
    var route = baseRoute + 'request';
    return axios.post(route, dataToSend, {
        'headers': {
            'Authorization': token
        }
    }).catch(function (error) {
        var status = error.response.status;
        if (status === 401) {
            sessionStorage.clear();
            window.location.href = '/';
        }
    })
        .then(res => {
            return res.data;
        })
}


export const confirmProduct = (dataToSend, token) => {
    var route = baseRoute + 'confirm';
    return axios.post(route, dataToSend, {
        'headers': {
            'Authorization': token
        }
    }).catch(function (error) {
        var status = error.response.status;
        if (status === 401) {
            sessionStorage.clear();
            window.location.href = '/';
        }
    })
        .then(res => {
            return res.data;
        })
}

export const getStandardMargins = (token) => {
    return axios.get(baseRoute + 'margin/standard', {
        'headers': {
            'Authorization': token
        }
    }).catch(function (error) {
        if (error.response === undefined) {
            window.location.href = '/sorry';
        }
        var status = error.response.status;
        if (status === 401) {
            sessionStorage.clear();
            window.location.href = '/';
        }
    })
        .then(res => {
            return res.data;
        })
}

export const getEmail = (id, token) => {
    return axios.get(baseRoute + 'email/' + id, {
        'headers': {
            'Authorization': token
        }
    }).catch(function (error) {
        if (error.response === undefined) {
            window.location.href = '/sorry';
        }
        var status = error.response.status;
        if (status === 401) {
            sessionStorage.clear();
            window.location.href = '/';
        }
    })
        .then(res => {
            return res.data;
        })
}

export const getEmails = (token) => {
    return axios.get(baseRoute + 'email', {
        'headers': {
            'Authorization': token
        }
    }).catch(function (error) {
        if (error.response === undefined) {
            window.location.href = '/sorry';
        }
        var status = error.response.status;
        if (status === 401) {
            sessionStorage.clear();
            window.location.href = '/';
        }
    })
        .then(res => {
            return res.data;
        })
}
