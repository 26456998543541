import React, { Component } from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { getEmails } from '../../logic/queryAPI.js';
import Loader from '../../Loader.jsx';

class MarginTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rawData: [],
            data: [],
            loadingData: false,
            vw: null,
            vh: null
        }
    }

    render() {

        const { data } = this.state;

        return (
        <div className="wrapper">
            <div className="searchStockBorrow">
                <input type="text" className="searchTerm" id="searchTerm" placeholder="Search by Instrument or Action" onChange={(e) => this.searchFiltered(e)} />
                <button className="searchButton" >
                    <i className="fa fa-search"></i>
                </button>
            </div>
            <div className="tableHolderStockBorrow">
                    <ReactTable
                        data={data}
                        loading={this.state.loadingData}
                        LoadingComponent={Loader}
                        columns={[
                            {
                                Cell: (<button>Edit</button>),
                                Header: "Email Notification Log",
                                columns: [
                                    {
                                        Header: "Instrument",
                                        accessor: "oneZeroCode",
                                    },
                                    {
                                        Header: "Action",
                                        accessor: "action"
                                    },
                                    {
                                        Header: "Time notified",
                                        accessor: "time"
                                    }
                                ]
                            }
                        ]}

                        defaultPageSize={20}
                        className="-striped -highlight summary"
                        getTheadProps={(state, rowInfo, column) => this.headerProps(state, rowInfo, column)}
                        getTheadGroupProps={(state, rowInfo, column) => this.headerProps(state, rowInfo, column)}
                    />
                    
            </div>
        </div>
        );
    }

    columnBorderProps(state, rowInfo) {
        return {
            style: {
                borderRight: '7px solid rgba(0,0,0,0.07)',
                borderLeft: '1px solid rgba(0,0,0,0.07)',
            }
        }
    }


    columnProps(state, rowInfo) {
        return {
            style: {
                borderRight: '1px solid rgba(0,0,0,0.07)',
                borderLeft: '1px solid rgba(0,0,0,0.07)',
            }
        }
    }

    rowProps(state, rowInfo, column) {
        return {
            style: {
                border: '1px solid rgba(0,0,0,0.07)',
            }
        }
    }

    // table setup helper
    headerProps(state, rowInfo, column) {
        return {
            style: {
                background: '#004b83',
                boxShadow: 'none',
                whiteSpace: 'pre-line !important',
                wordWrap: 'break-word',
                // border: '1px solid rgba(0,0,0,0.07)'              
            }
        }
    }

    groupHeaderProps(state, rowInfo, column) {
        return {
            style: {
                background: '#004b83',
                boxShadow: 'none',
                
                // borderLeft: '7px solid rgba(0,0,0,0.07)',
                // borderRight: '7px solid rgba(0,0,0,0.07)'
            }
        }
    }

    searchFiltered = (e) => {
        var searchTerm = document.getElementById("searchTerm").value.toLowerCase();
        var searchResults = []
        var data_before_search = this.state.rawData;
        // console.log(summary_data_before_search[1].Name);
        for (var i = 0; i < data_before_search.length; i++) {
            if (data_before_search[i].oneZeroCode === undefined) {
                break;
            }
            if (data_before_search[i].oneZeroCode.toLowerCase().includes(searchTerm) || data_before_search[i].action.toLowerCase().includes(searchTerm)) {
                searchResults.push(data_before_search[i])
            }
        }
        this.setState({
            data: searchResults
        })
    }


    componentDidMount() {
        this.setState({
            loadingData: true
        });
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
        this.getData();
    }

    getData() {

        getEmails(this.props.token).then(res => {
            this.setState({
                data: res,
                rawData: res,
                loadingData: false
            });
        })
    }

    componentWillUnmount() {
        clearInterval(this.interval);
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions = () => {
        this.setState({ vw: window.innerWidth / 100, vh: window.innerHeight / 100});
      }

}

export default MarginTable