import React from 'react';
import './sorry.css';
import SorryChick from '../images/sorry-chick.png'


class Sorry extends React.Component {
  render = () => (
    <div>
      <div className="main-sorry"><h2>Something went wrong :( Please try navigating <a href="/" style={{color: "#004b83"}}>home</a>.</h2><img id="sorry-img" src={SorryChick} alt="Sorry"></img><h3>404</h3></div>

    </div>
  )

  componentDidMount() {

  }
}
export default Sorry;
