import React from 'react';
import './sections.css';

// images
import common from './img/3.4-common.png';
import trade from './img/3.4-trade.png';
import sessions from './img/3.4-sessions.png';
import symbolsImport1 from './img/3.4-symbols-import-1.png';
import symbolsImport2 from './img/3.4-symbols-import-2.png';

class MT5Symbols extends React.Component {
    render = () => (
        <div>            
            <h2>3.0 MT5 Server Configuration</h2>
            <h2>3.4 Symbols</h2>
            <p>26 Degrees has provided an MT5 config file that will allow you to 'batch import Single Stock CFD symbols using a .JSON file. This will allow you to align your symbol settings with 26 Degrees's recommendations and control other settings such as Margin requirements.</p>
            <p>26 Degrees recommends placing all single Stock CFD symbols under a single, separate MT5 Symbol Group. This will allow you to easily apply settings to all single Stock CFD symbols, without impacting any other symbols which are currently on your MT5 server</p>
            <p><b>Instructions for loading the file:</b></p>
            <ol>
                <li>Download the File (see 6.0 Downloads)</li>
                <li>Open MT5 Admin</li>
                <li>
                    <p>Right click on <b>Symbols</b> and click <b>Import</b></p>
                    <img src={symbolsImport1}
                        alt="not found"
                        style={{
                            width: '50vw',
                            maxWidth: '450px',
                            height:'auto',                                                        
                        }}
                    ></img>
                </li>
                <li>Select the "InvastGlobal-MT5-SingleStocks.json" file and click <b>Open</b></li>
                <li>
                    <p><b>IMPORTANT: </b>Tick the <b>Stocks</b> group then click <b>Import</b></p>
                    <img src={symbolsImport2}
                        alt="not found"
                        style={{
                            width: '50vw',
                            maxWidth: '500px',
                            height:'auto',                                                        
                        }}
                    ></img>
                </li>
            </ol>
            <br></br>
            <p><b>Following is an explanation of the Symbol Settings imported above.</b></p>
            <ul>
                <li>
                    <p><b>Common</b></p>
                    <p className={'red-note'}>- Digits must be set in accordance with the 26 Degrees Symbol Specifications.</p>
                    <p>- Chart Mode for ALL Symbols = By Bid Price</p>
                    <p>- Market Depth for ALL Symbols = Off</p>
                    <p>- Base / Profit / Margin currency digits do not need to match the symbol digits.</p>
                    <img src={common}
                        alt="not found"
                        style={{
                            width: '50vw',
                            maxWidth: '700px',
                            height:'auto',                                                        
                        }}
                    ></img>
                </li>
                <li>
                    <p><b>Quotes</b> - "Allow realtime quotes from datafeeds" should be ticked.</p>                                        
                </li>
                <li>
                    <p><b>Trade</b> - Should be configured as per the below:</p>
                    <img src={trade}
                        alt="not found"
                        style={{
                            width: '50vw',
                            maxWidth: '700px',
                            height:'auto',                                                        
                        }}
                    ></img>
                    <p className={'red-note'}>- Setting the calculation method to "CFD" in your MT5 symbol setup ensures that orders are triggered off the bid/offer which is the desired behaviour. The "Exchange Stocks" calculation type triggers orders off the last traded price which is not desired.</p>                    
                    <p className={'red-note'}>- To allow fractional share trading set the "Volumes Minimum" and "Volumes Step" to 0.1.</p>                    
                </li>
                <li>
                    <p><b>Execution</b> - <span className={'red-note'}>Execution: Must be set to Market</span></p>
                </li>
                <li>
                    <p><b>Margin & Margin Rates</b></p>
                    <p>- Allows control of inital margin rate, maintenance margin rate, when margin is checked, etc.</p>
                    <p>- 26 Degrees has no recommendations for these settings.</p>
                </li>
                <li>
                    <p><b>Swaps</b></p>
                    <p>- To best align financing charges between 26 Degrees and your client base, you may wish to enable swaps in % terms using current price with the 3-day swap applied on Friday.</p>                    
                </li>
                <li>
                    <p><b>Sessions</b> - Sessions for all equity instruments in MT5 should be set as follows:</p>                
                    <img src={sessions}
                        alt="not found"
                        style={{
                            width: '50vw',
                            maxWidth: '700px',
                            height:'auto',                                                        
                        }}
                    ></img>
                    <p className={'red-note'}>26 Degrees recommends NOT changing the session times in your MT5 as this may restrict your client’s ability to place pending (limit and stop), orders outside of regular trading sessions for each exchange. The underlying session times for pricing and execution will be handled by the session profiles which will be enabled and configured on your oneZero Hub- described later in this document. </p>
                </li>
            </ul>
        </div>
    )
}

export default MT5Symbols;