import React, { Component } from 'react';
import './review.css';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { getDetails, review } from '../logic/queryAPI.js';
import 'flatpickr/dist/themes/material_green.css';
import { Link } from 'react-router-dom';
import Loader from '../Loader.jsx';
import { confirmAlert } from 'react-confirm-alert';
import { sendEmailNotification } from '../logic/queryAWSAPI.js';
import 'react-confirm-alert/src/react-confirm-alert.css';

class Review extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: undefined,
            tableData: [{
                definition: "",
                description: "",
                value: "",
            }],
            tkr: false,
            id: props.id,
            vw: null,
            loading: true
        };

    }

    componentDidMount() {
        ;
        this.setState({
            loadingSummary: true
        })
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
        getDetails(this.props.id, this.props.token).then(res => {
            var tableparams = [];
            for (var n = 0; n < res[0]['params'].length; n++) {
                if (res[0]['params'][n]['displayonwebclient'] === 1) {
                    tableparams.push(JSON.parse(JSON.stringify(res[0]['params'][n])));
                }
            }
            this.setState({
                data: res,
                tableData: tableparams,
                loading: false
            })
        });
        var form = document.getElementById("form-container");
        form.addEventListener("input", function () {
            this.updateSampleTable();
        }.bind(this));
    }
    render() {
        return (

            <div>
                <div id="fullpage-loader">
                    <Loader loading={this.state.loading}></Loader>
                </div>
                <div id="content-container">
                    <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.6.3/css/all.css" integrity="sha384-UHRtZLI+pbxtHCWp1t77Bi1L4ZtiqrqD80Kn4Z8NTSRyMA2Fd33n5dQ8lWUE00s/" crossOrigin="anonymous" />
                    <div className="wrapper" id="review-box">
                        <div id="form-container">
                            <h2 id="review-header">Review actionId:&nbsp;{this.state.id}</h2>
                            <button id="rm-action"
                                className="review-button"
                                onClick={(e) => this.confirmRemove(this.props.token)}
                            >
                                Remove action from Client and IFS portals</button>
                            <br></br>
                            <br></br>
                            <fieldset className="review-form" style={{ display: "inline-block" }}>
                                Parameter:&nbsp;
                <select className="param">
                                    <option default></option>
                                    {this.listParams()}
                                </select>&nbsp;&nbsp;
                <input type="text" className="newParam" placeholder="Create parameter"></input>
                                <p className='note'>If accepting Corporate Action as-is, leave the above blank and submit.<br></br> Otherwise, choose a value for <b>one</b> of the above</p>

                                <span className="currValue"></span>
                                <br></br><br></br>
                                <span className="rmspan">Remove parameter?<input type="checkbox" id="rm" className="rm" /><label htmlFor="rm" className="rmlbl"></label></span><br></br><br></br>
                                New Value:&nbsp;&nbsp;
                <input type="text" className="newValue" placeholder="ensure correct syntax"></input>
                                <br></br>
                                <p className='note'></p>

                            </fieldset>
                            {/* <br></br> */}
                        </div>
                        <h4 style={{ fontWeight: 'normal', fontSize: '1vw', position: 'relative', top: '-1vw' }}>Once submitted, this Corporate Action (and any modifications made above) will be viewable to clients</h4>

                        <button id="add-form" className="review-button" onClick={(e) => {
                            this.addForm(e)
                        }}>Review another parameter</button>
                        <button id="submit-review" className="review-button" onClick={(e) => {
                            this.sendreview(e, this.props.token)
                        }}>Submit action</button>
                    </div>

                    <ReactTable
                        data={this.state.tableData}
                        columns={[
                            {
                                Header: "Client Perspective of Selected Action",
                                columns: [
                                    {
                                        Header: "Description",
                                        accessor: "description",
                                        width: 12 * this.state.vw
                                    },
                                    {
                                        Header: "Value",
                                        accessor: "value",
                                        width: 8 * this.state.vw,
                                        getProps: (state, rowInfo, column) => {
                                            if (rowInfo && (rowInfo.row.description === 'IMPORTANT NOTE' || rowInfo.row.description === 'GUIDANCE')) {
                                                return {
                                                    style: {
                                                        border: rowInfo && rowInfo.row.value !== '-' ? '1.5pt red solid' : null,
                                                    }
                                                };
                                            }
                                            return {}
                                        }
                                    },
                                    {
                                        Header: "Definition",
                                        accessor: "definition",
                                        width: 27 * this.state.vw,
                                        style: { overflow: "visible" },
                                        Cell: this.defCell,
                                    },
                                ],

                            }
                        ]}
                        defaltFiltered={[
                            {
                                id: 'displayonwebclient',
                                value: 1
                            }
                        ]}
                        defaultPageSize={10}
                        className="details"
                        id="review-table"
                        getTheadProps={(state, rowInfo, column) => this.headerProps(state, rowInfo, column)}
                        getTheadGroupProps={(state, rowInfo, column) => this.headerProps(state, rowInfo, column)}
                        getTrProps={(state, rowInfo) => {
                            if (rowInfo && rowInfo.row) {
                                return {
                                    style: {
                                        display: rowInfo.original.value === "REMOVE" ? "none" : "",
                                        background: rowInfo.original.description === "IMPORTANT NOTE" || rowInfo.original.description === "GUIDANCE" ? 'rgba(255,0,0,0.33)' : 'white',
                                    }
                                }
                            } else {
                                return {}
                            }
                        }
                        }

                    />
                </div>
            </div>
        )
    }

    defCell({ row, value, columnProps: { rest: { someFunc } } }) {
        if (row.description === "GUIDANCE") {
            return <p><Link to={window.location.origin + "/corporate-actions/guide#" + row.definition} style={{ color: 'blue' }}>Further Guidance</Link></p>
        }
        else {
            return <p style={{ margin: '0' }}>{value}</p>
        }

    };
    listParams() {
        if (this.state.data === undefined) {
            return undefined;
        }

        var selectors = document.getElementsByClassName('param');
        var selector = selectors[selectors.length - 1]
        var options = selector.children;
        var goodtogo;

        for (var n = 0; n < this.state.data[0]['params'].length; n++) {
            if (this.state.data[0]['params'][n]['description'] === "BBG Ticker" && !this.state.tkr) {
                document.getElementById('review-header').innerHTML += "<br/> BBG ticker: " + this.state.data[0]['params'][n]['value'];
                this.setState({ tkr: true });
            }
            goodtogo = true;
            for (var i = 0; i < options.length; i++) {
                if (options[i].value === this.state.data[0]['params'][n]['description']) {
                    goodtogo = false;
                }
            }
            if (goodtogo && this.state.data[0]['params'][n]['description'] !== "BBG Ticker") {
                var op = document.createElement('option');
                op.value = this.state.data[0]['params'][n]['description'];
                op.innerHTML = this.state.data[0]['params'][n]['description'];
                selector.appendChild(op);
            }
        }
        var otherParams = [];
        for (n = 0; n < otherParams.length; n++) {
            goodtogo = true;
            for (i = 0; i < options.length; i++) {
                if (options[i].value === otherParams[n]) {
                    goodtogo = false;
                }
            }
            if (goodtogo) {
                op = document.createElement('option');
                op.value = otherParams[n];
                op.innerHTML = otherParams[n];
                selector.appendChild(op);
            }
        }
        selector.addEventListener('input', function (evt) {
            evt.preventDefault();
            var path = evt.path || (evt.composedPath && evt.composedPath());
            var fs = path[1];
            var currVal = undefined;
            var othIdx = otherParams.indexOf(path[0].value);
            if (othIdx >= 0) {
                for (var n = 0; n < this.state.summaryData.length; n++) {
                    // Below "==" is required as the types do not match, we are just checking values
                    // Consider a type conversion in the future
                    if (this.state.summaryData[n].actionId === this.state.id) {
                        if (otherParams[othIdx] === 'ccy') {
                            otherParams[othIdx] = 'currencyCode';
                        }

                        currVal = this.state.summaryData[n][otherParams[othIdx]];
                    }
                }
            } else {
                for (n = 0; n < this.state.data[0]['params'].length; n++) {
                    if (this.state.data[0]['params'][n]['description'] === path[0].value) {
                        currVal = this.state.data[0]['params'][n]['value'];
                        if (currVal === " ")
                            currVal = "none";
                        break;
                    }
                }
            }
            var div = fs.getElementsByClassName('currValue')[0];
            if (currVal !== undefined) {
                div.innerHTML = "Current Value: " + currVal;
            } else { div.innerHTML = "" }
        }.bind(this))
    }

    addForm(e) {
        e.preventDefault();
        var nextid = document.getElementById('form-container').childNodes.length;
        var initfs = document.createElement('fieldset');
        initfs.classList.add('elem-initial');
        document.getElementById('form-container').appendChild(initfs);
        var newElem = `Parameter:&nbsp;<select class="param"><option default></option>this.listParams()}</select>&nbsp;&nbsp;<input type="text" class="newParam" placeholder="Create parameter"></input><p class='note'>If accepting Corporate Action as-is, leave the above blank and submit.<br/>Otherwise, choose a value for <b>one</b> of the above</p><span class="currValue"></span><br></br><span class="rmspan">Remove parameter?<input type="checkbox" id="rm-` + nextid + `" class="rm"/><label for="rm-` + nextid + `" class="rmlbl"></label></span><br></br>New Value:&nbsp;&nbsp;<input type="text" class="newValue" placeholder="ensure correct syntax"></input><p class='note'></p>`;
        initfs.classList.add('review-form');
        initfs.innerHTML = newElem;
        setTimeout(function () {
            initfs.classList.remove('elem-initial')
        }, 1)
        this.setState({
            reviewEventListenerInstantiated: true
        });
        this.listParams();
    }

    sendreview(e, token) {
        var changes = this.getFormVals();
        var dataToSend = {
            "paramsToChange": {
                "actionId": this.state.id, "changes": changes
            }
        };
        review(dataToSend, token).then(response => {
            window.location.href = '/corporate-actions/tool';
        });
    }

    getFormVals() {
        var params = document.getElementsByClassName('param');
        var newVals = document.getElementsByClassName('newValue');
        var rms = document.getElementsByClassName('rm');
        var newPrms = document.getElementsByClassName('newParam');
        var changes = [];
        for (var n = 0; n < params.length; n++) {
            if (!rms[n].checked) {
                if (params[n].value === "Short Amount") {
                    if (newVals[n].value.charAt(0) !== "-") {
                        newVals[n].value = "-" + newVals[n].value;
                    }
                }
                if (newPrms[n].value === "") {
                    changes.push({ "parameter": params[n].value, "newValue": newVals[n].value, "applied": false });
                } else {
                    changes.push({ "parameter": newPrms[n].value, "newValue": newVals[n].value, "applied": false });
                }
            } else {
                changes.push({ "parameter": params[n].value, "newValue": 'REMOVE', "applied": false });
            }
        }
        return changes
    }

    headerProps(state, rowInfo, column) {
        return {
            style: {
                background: '#004b83',
                boxShadow: 'none',
                whiteSpace: 'pre-line !important',
                wordWrap: 'break-word',
            }
        }
    }

    groupHeaderProps(state, rowInfo, column) {
        return {
            style: {
                background: '#004b83',
                boxShadow: 'none',
            }
        }
    }

    removeAction(token) {
        var dataToSend = {
            "paramsToChange": {
                "actionId": this.state.id, "changes": "remove"
            }
        };
        review(dataToSend, token).then(response => {
            window.location.href = '/corporate-actions/tool';
        });
    }
    confirmRemove(token) {
        if (document.getElementById('rm-action').innerHTML === "Click again to confirm. This will remove this action from <u>all</u> portals.") {
            this.removeAction(token);
        } else {
            document.getElementById('rm-action').innerHTML = "Click again to confirm. This will remove this action from <u>all</u> portals.";
        }
    }

    updateWindowDimensions = () => {
        this.setState({ vw: window.innerWidth / 100, vh: window.innerHeight / 100 });
    }

    updateSampleTable() {
        var changes = this.getFormVals();
        var table = this.state.data[0].params;
        var changedData = [];
        for (var i = 0; i < table.length; i++) {
            var row = table[i];
            if (row["displayonwebclient"] === 1) {
                var first = true;
                for (var n = 0; n < changes.length; n++) {
                    var change = changes[n];
                    for (var p = 0; p < table.length; p++) {
                        if (table[p]["description"] === change["parameter"]) {
                            change["applied"] = true;
                        }
                    }
                    if (first) {

                        var newRow = JSON.parse(JSON.stringify(row));
                        if (change["parameter"] === row["description"]) {
                            change["applied"] = true;
                            newRow["value"] = change["newValue"];
                        }
                        changedData.push(newRow);
                    } else {
                        if (change["parameter"] === row["description"]) {
                            change["applied"] = true;
                            newRow["value"] = change["newValue"];
                        }
                    }
                    first = false;
                }
            }
        }
        for (n = 0; n < changes.length; n++) {
            change = changes[n];
            if (!change["applied"]) {
                changedData.push({
                    "description": change["parameter"],
                    "value": change["newValue"]
                })
            }
        }
        this.setState({ tableData: changedData })
    }

}

export default Review;