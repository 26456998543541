export const formatNumber = (num) => {
    num = Math.round(num * 100) / 100
    if (num < 0) {
        num = "(" + num.toLocaleString().slice(1) + ")";
    } else {
        num = num.toLocaleString()
    }
    return num
}

export const dateToString = (date) => {
    return datetimeToString(date).slice(0,10);
}

export const datetimeToString = (datetime) => {
    return datetime.toJSON();
}

export const prevBusinessDate = (date) => {
    var newDate = new Date(date);
    newDate.setDate(newDate.getDate() - 1);
    while (!isBusinessDate(newDate)) {
        newDate.setDate(newDate.getDate() - 1);
    }
    return newDate;
}

export const currDate = (date) => {
    var newDate = new Date(date);
    newDate.setDate(newDate.getDate());
    return newDate;
}

export const currDateSub7 = (date) => {
    var newDate = new Date(date);
    newDate.setDate(newDate.getDate() - 7);
    while (!isBusinessDate(newDate)) {
        newDate.setDate(newDate.getDate() - 1);
    }
    return newDate;
}

export const prevBusinessDateAdd14 = (date) => {
    var newDate = new Date(date);
    newDate.setDate(newDate.getDate() + 13);
    while (!isBusinessDate(newDate)) {
        newDate.setDate(newDate.getDate() - 1);
    }
    return newDate;
}

function isBusinessDate(date) {
    var day = date.getDay();
    if (day === 0 || day === 6) {
        return false;
    }
    return true;
}


export const getColumnWidth = (rows, accessor, headerText) => {
    const maxWidth = 200
    const defaultWidth = 200
    const magicSpacing = 10
    const cellLength = Math.max(
      ...rows.map(row => (`${row[accessor]}` || '').length),
      headerText.length,
    )
    if (cellLength * magicSpacing < 50){
        return defaultWidth
    }
    else {
        return Math.min(maxWidth, cellLength * magicSpacing)
    }
  }



  
export default function parseJwt(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    return JSON.parse(jsonPayload)
  }