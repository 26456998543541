import React from 'react';

class OrderHandling extends React.Component {
    render = () => (
        <div>            
            <h2>5.0 Accepted Order Types and Order Handling</h2>
            <p>The 26 Degrees oneZero LD4 DMA Hub will accept the following order types from your oneZero hub for single stocks CFDs:</p>
            <ul>
                <li>Limit GTC</li>
                <li>Market GTC</li>
            </ul>
            <p>These order mappings were configured under Section 4.1 26 Degrees DMA Maker Adapter - Execution Method Settings.</p>
            <p className='red-note'>As many of the Equity exchanges do not accept ‘Market’ order types, 26 Degrees will convert <u>all Market orders</u> to Limit orders with a ‘price delta’ before passing them to the relevant exchange for execution. This conversion to a Limit order type also applies to ‘Stop’ orders. </p>
            <p className='red-note'>This process of converting ‘Market Orders’ to Limit orders within the 26 Degrees oneZero LD4 DMA hub may result in a Market order receiving either a partial fill or no fill at all. While this should occur infrequently, the broker must be aware of this risk and ensure that their clients are made aware of such a possibility.</p>

            <h2>Annex 1 - Accepted Order Types and Order Handling</h2>
            <table className='mt5-gateways' style={{
                width: '60vw',
                maxWidth: '900px'
            }}>
                <thead>
                    <th>Client MT5 Order Type</th>
                    <th>Sent to 26 Degrees LD4 DMA Hub As</th>
                    <th>Sent to 26 Degrees Execution Venue As</th>
                </thead>
                <tbody>
                    <tr><td>ExecutionMarketFOK</td><td>Market GTC</td><td>Limit + Delta</td></tr>
                    <tr><td>ExecutionMarketIOC</td><td>Market GTC</td><td>Limit + Delta</td></tr>
                    <tr><td>ExecutionLimitFOK</td><td>Limit GTC</td><td>Limit at Requested Price</td></tr>
                    <tr><td>ExecutionLimitIOC</td><td>Limit GTC</td><td>Limit at Requested Price</td></tr>
                    <tr><td>ExecutionInstantFOK</td><td>Market GTC</td><td>Limit + Delta</td></tr>
                    <tr><td>ExecutionInstantIOC</td><td>Market GTC</td><td>Limit + Delta</td></tr>
                    <tr><td>ExecutionStopFOK</td><td>Market GTC</td><td>Limit + Delta</td></tr>
                    <tr><td>ExecutionStopIOC</td><td>Market GTC</td><td>Limit + Delta</td></tr>
                    <tr><td>ExecutionExchangeFOK</td><td>Market GTC</td><td>Limit + Delta</td></tr>
                    <tr><td>ExecutionExchangeIOC</td><td>Market GTC</td><td>Limit + Delta</td></tr>
                </tbody>
            </table>
        </div>
    )
}

export default OrderHandling;
