import React from 'react';
import './sections.css';
import riskmgmt from './img/3.1-riskmgmt.png';

class MT5Groups extends React.Component {
    render = () => (
        <div>
            <h2>3.0 MT5 Server Configuration</h2>
            <h2>3.1 Groups</h2>
            <b>Position Account System - Netting</b>
            <p>MT5 has two different “position accounting systems”. The first being “netting” and the second being “hedging”. This methodology is controlled by the “risk management” setting under the “margin” tab of the account group.</p>
            <p>26 Degrees strongly recommends using the “netting” methodology otherwise MT5 will not allow the partial closing of long positions where the symbol is set to “Long only”.</p>
            <p>To activate “Netting” you must set “Risk Management” to “for Retail Forex, CFD, Futures” within the “Margin” tab of the relevant groups.</p>
            <img src={riskmgmt}
                        alt="not found"
                        style={{
                            width: '50vw',
                            maxWidth: '700px',
                            height:'auto',                                                        
                        }}
                    ></img><br></br>
            <b>Market Data Permissions</b>
            <p>The oneZero Market Data Sentinel tool reports Market Data Usage for ALL Trading Accounts that have access to the relevant Exchange Data. You may wish to consider creating Groups in your MT5 server that allow for appropriate segregation of clients based on their Market Data preferences.</p>
            <p className={'red-note'}>NB: Trading Accounts within Groups subscribed to all symbols will be billed for Market Data from EVERY exchange</p>
        </div>
    )
}

export default MT5Groups;