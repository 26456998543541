import React, { Component } from "react";
import LoadingGif from './images/26d-loading.gif';

class Loader extends Component {
    render() {
        return (
            <div className="-loading -active">
                {this.props.loading ? (
                    <div className="-loading-inner">
                        <img className="-loading-gif" src={LoadingGif} alt="loading"/>
                    </div>
                ) : (
                    <div />
                )}
            </div>
        );
    }
}

export default Loader;