import React, { Component } from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { dateToString, currDate, currDateSub7 } from '../logic/utilities.js';
import { getBenchmarks} from '../logic/queryAPI.js';
import moment from "moment";
import 'flatpickr/dist/themes/material_green.css';
import Flatpickr from 'react-flatpickr';
import Loader from '../Loader.jsx';
import './benchmarks.css';

class Benchmarks extends Component {
    constructor(props) {
        super(props);
        var yesterday = dateToString(currDate(new Date()));
        var yesterday_7 = dateToString(currDateSub7(new Date()));

        this.state = {
            startDate: yesterday_7,
            endDate: yesterday,
            data: [],
            loadingData: false
        }
    }

    render() {
        // var obj = JSON.parse(formatted)

        const { data } = this.state;

        return (


            <div className="wrapper" id="md-wrapper">
                <div id="spacing"/>
                <div className='dateSelector'>
                    <p>Showing results for:</p>
                    <div className="startDate">
                        <Flatpickr value={[this.state.startDate, this.state.endDate]}
                            options={{
                                mode: "range",
                                dateFormat: "Y-m-d",
                            }}
                            onChange={(dateStr) => this.updateDates(dateStr)}
                        />
                    </div>
                </div>
                            <div id="b-table-holder">
                <div id="benchmarks-table">
                    <ReactTable
                        data={data.data}
                        loading={this.state.loadingData}
                        LoadingComponent={Loader}
                        columns={[
                            {
                                Cell: (<button>Edit</button>),
                                Header: "Currency Benchmarks",
                                columns: [
                                    {
                                        Header: "Date",
                                        accessor: "date",
                                        style: {
                                            textAlign: 'left'
                                        }
                                    },
                                    {
                                        Header: "Benchmark",
                                        accessor: "benchmark",
                                        style: {
                                            textAlign: 'left'
                                        }
                                    },
                                    {
                                        Header: "Value",
                                        accessor: "value",
                                        style: {
                                            textAlign: 'right'
                                        }
                                    }
                                ]
                            }

                        ]}
                        defaultSorted={[
                            {
                                id: 'date',
                                desc: true
                            }
                        ]

                        }
                        defaultPageSize={10}
                        className="-striped -highlight summary"
                        getTheadProps={(state, rowInfo, column) => this.headerProps(state, rowInfo, column)}
                        getTheadGroupProps={(state, rowInfo, column) => this.headerProps(state, rowInfo, column)}
                    />
                </div></div>
            </div>
        );
    }


    columnBorderProps(state, rowInfo) {
        return {
            style: {
                borderRight: '7px solid rgba(0,0,0,0.07)',
                borderLeft: '1px solid rgba(0,0,0,0.07)',
            }
        }
    }


    columnProps(state, rowInfo) {
        return {
            style: {
                borderRight: '1px solid rgba(0,0,0,0.07)',
                borderLeft: '1px solid rgba(0,0,0,0.07)',
            }
        }
    }

    rowProps(state, rowInfo, column) {
        return {
            style: {
                border: '1px solid rgba(0,0,0,0.07)',
            }
        }
    }

    // table setup helper
    headerProps(state, rowInfo, column) {
        return {
            style: {
                background: '#004b83',
                boxShadow: 'none',
                whiteSpace: 'pre-line !important',
                wordWrap: 'break-word',
                // border: '1px solid rgba(0,0,0,0.07)'              
            }
        }
    }

    groupHeaderProps(state, rowInfo, column) {
        return {
            style: {
                background: '#004b83',
                boxShadow: 'none',

                // borderLeft: '7px solid rgba(0,0,0,0.07)',
                // borderRight: '7px solid rgba(0,0,0,0.07)'
            }
        }
    }

    updateDates = (date) => {
        this.setState({
            startDate: date[0],
            endDate: date[1]
        })
        // this.props.setAppDatesState(date[0],date[1]);
        this.getData();

    }

    getData() {
        this.setState({
            loadingSummary: true
        });
        getBenchmarks(moment(this.state.startDate).format('Y-MM-DD'), moment(this.state.endDate).format('Y-MM-DD'),this.props.token).then(res => {
            this.setState({
                data: res,
                loadingData: false
            });
        })
    }

    logout() {
        sessionStorage.clear();
        window.location.href = '/'
    }


    componentDidMount() {

        this.updateWindowDimensions();
        // window.confirm();
        this.setState({
            loadingData: true
        });
        window.addEventListener('resize', this.updateWindowDimensions);

        this.getData();
    }

    componentWillUnmount() {
        clearInterval(this.interval);
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions = () => {
        this.setState({ vw: window.innerWidth / 100, vh: window.innerHeight / 100 });
    }

}

export default Benchmarks;