import React, { Component } from 'react';
import ReactTable from 'react-table';
import './corporateActionTool.css';
import 'react-table/react-table.css';
import { getDetails, getEmail } from '../logic/queryAPI.js';
import { dateToString, datetimeToString, prevBusinessDate, prevBusinessDateAdd14 } from '../logic/utilities';
import { getActionsByDateRange, getActionsBySymbols } from '../logic/queryAPI';
import { sendEmailNotification } from '../logic/queryAWSAPI'
import moment from "moment";
import { CSVLink } from "react-csv";
import 'flatpickr/dist/themes/material_green.css';
import Flatpickr from 'react-flatpickr';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import CSVReader from 'react-csv-reader';
import ReactTooltip from 'react-tooltip'
import { Link } from 'react-router-dom';
import Loader from '../Loader.jsx';

function processDetailsState(actionSummary, actionParams) {
    // Process Corporate actions without changing underlying data sourced from the datastore.
    if ("Cash Dividend" === actionSummary["action"]) {
        const customActionParams = [];
        let customDvDLong;
        let isUSIncorporated;

        actionParams.forEach(param => {
            if ("CUSTOM_DVD_CTRY" === param.field && "US" === param.value) {
                isUSIncorporated = true;
            }
            if ("CUSTOM_DVD_LONG_AMT" !== param.field) {
                customActionParams.push({...param});
            } else {
                customDvDLong = {...param};
            }
        })

        customActionParams.push(customDvDLong);
        return customActionParams;
    }
    return actionParams;
}

class CorporateActionTool extends Component {
    constructor(props) {
        super(props);
        var yesterday = dateToString(prevBusinessDate(new Date()));
        var yesterday_14 = dateToString(prevBusinessDateAdd14(new Date()));

        if (props.startDate === null && props.endDate === null) {
            props.setAppDatesState(yesterday, yesterday_14);
        }

        this.downloadSummary = this.downloadSummary.bind(this);
        this.downloadDetailed = this.downloadDetailed.bind(this);
        this.state = {
            data: [],
            valid_symbols: null,
            summary_data: [],
            summary_data_before_search: [],
            details_data: [{
                description: "",
                value: "",
            }],
            filters: {
                start_date: props.startDate,
                end_date: props.endDate
            },
            id: '',
            button: '',
            selected: null,
            csvDataSummary: [],
            csv_imported: false,
            csv_import_data: null,
            csvDataDetailed: [],
            vw: null,
            vh: null,
            reviewed: false,
            loadingSummary: false,
            loadingDetailed: false,
            loadingCsv: false,
            summaryRows: sessionStorage.getItem('summaryRows'),
        }
    }

    downloadSummary(event) {

        this.setState({
            loadingCsv: true
        })
        const currentRecords = this.state.summary_data;
        const csvDataSummary = [["actionId", "26D CFD Symbol", "26D CFD Name", "CFD Corporate Action", "Currency", "Announced Date", "Updated Date", "Execution Date", "Revision"]]
        currentRecords.forEach(function (action) {
            csvDataSummary.push([action.actionId, action.productCode, action.description, action.action, action.currencyCode, action.actionDate, action.amendDate, action.effectiveDate, action.revision]);
        });
        this.setState({ csvDataSummary: csvDataSummary }, () => {
            // click the CSVLink component to trigger the CSV download
            this.csvLinkSummary.link.click()
        })
        this.setState({
            loadingCsv: false
        })


    }

    downloadDetailed(event, token) {
        this.setState({
            loadingCsv: true
        })
        const currentRecords = this.state.summary_data;

        let actionSummaryMap = new Map(
            currentRecords.map(actionSummary => [actionSummary.actionId, actionSummary])
        );

        const csvDataDetailed = [["actionId", "26D CFD Symbol", "description", "value"]];
        const actionIds = [];
        currentRecords.forEach(function (action) {
            actionIds.push([action.actionId, action.productCode]);
        });
        // Now call ze api
        // Form query string
        var actionList = "";
        actionIds.forEach(function (id) {
            actionList += id[0] + ",";
        })
        actionList = actionList.substr(0, actionList.length - 1);

        if (token !== null && actionList !== "") {
            getDetails(actionList, token).then(res => {

                res.forEach(function (action) {
                    actionIds.forEach(function (id) {
                        if (id[0] === action.actionId) {
                            const processedActionParams = processDetailsState(actionSummaryMap.get(action.actionId), action.params)
                            processedActionParams.forEach(function (param) {
                                csvDataDetailed.push([action.actionId, id[1], param.description, param.value])
                            })
                        }
                    })

                })
                this.setState({
                    csvDataDetailed: csvDataDetailed
                })


                this.setState({ csvDataDetailed: csvDataDetailed, loadingCsv: false }

                    , () => {
                        // click the CSVLink component to trigger the CSV download
                        this.csvLinkDetailed.link.click()
                    })
            })
        } else {
            this.setState({ loadingCsv: false })
        }


    }


    render() {

        const { summary_data } = this.state;
        const { details_data } = this.state;



        return (


            <div className="wrapper">
                <div id="fullpage-loader">
                    <Loader loading={this.state.loadingCsv}></Loader>
                </div>
                <div id="right-csv-button">
                    <ul className="csv-import">
                        <li id="csv-import-li" data-tip="The required file format is a comma-separated-values (CSV) file with each 26D CFD symbol listed on a new line.">
                            <CSVReader
                                cssClass="csv-reader-input"
                                cssInputClass="csv-reader-button"
                                label="Upload a CSV with 26D CFD Symbols"
                                onFileLoaded={this.CSVupload}
                                onError={this.csvError}
                                inputId="ObiWan"
                                inputStyle={{ color: 'red' }}
                            />
                            <ReactTooltip effect='solid' place="bottom" />
                        </li>
                    </ul>
                </div>
                <p id="numSymbolsUploaded" style={{ backgroundColor: 'white' }}>{this.state.valid_symbols === null ? "No symbols uploaded" : this.state.valid_symbols + " valid symbols uploaded"}</p>
                <div className='dateSelector'>
                    <p>Showing results for:</p>
                    <div className="startDate">
                        <Flatpickr value={[this.props.startDate, this.props.endDate]}
                            options={{
                                mode: "range",
                                dateFormat: "Y-m-d",
                            }}
                            onChange={(dateStr) => this.updateDates(dateStr)}
                        />
                    </div>
                </div>


                <div>
                    <CSVLink data={this.state.csvDataSummary} filename={"actionsummaries_" + datetimeToString(new Date()) + ".csv"} className="hidden" ref={(r) => this.csvLinkSummary = r} target="_blank" />

                    <CSVLink data={this.state.csvDataDetailed} filename={"actiondetails_" + datetimeToString(new Date()) + ".csv"} className="hidden" ref={(r) => this.csvLinkDetailed = r} target="_blank" />
                </div>
                <div id="right-csv-button">
                    <ul className="ks-cboxtags-2">
                        <li><input type="checkbox" id="summary_download" onClick={this.downloadSummary} />
                            <label htmlFor="summary_download">Download Corporate Action Summaries (CSV)</label></li>
                        <div className="search">
                            <input type="text" className="searchTerm" id="searchTerm" placeholder="Search by Symbol, Name or Corporate Action" onChange={(e) => this.searchFiltered(e)} />
                            <button className="searchButton" >
                                <i className="fa fa-search"></i>
                            </button>
                        </div>
                        <li className="rightButton"><input type="checkbox" id="detail_download" onClick={(e) => this.downloadDetailed(e, this.props.token)} />
                            <label htmlFor="detail_download">Download Details of ALL Filtered Corporate Actions (CSV)</label></li>
                    </ul>
                </div>
                <Link to={{ pathname: '/review', id: this.state.id, action: this.state.summary_data.filter(s => s.actionId === this.state.id) }}><button id="admin-only">Review</button></Link>
                {/* Below removed as it is not currently functional */}
                {/* {this.state.reviewed && <button id="email-admin-only" onClick={this.emailPopup.bind(this)}>Send Email</button>} */}
                <div className="tableHolder">
                    <ReactTable
                        data={summary_data}
                        loading={this.state.loadingSummary}
                        LoadingComponent={Loader}
                        columns={[
                            {
                                Cell: (<button>Edit</button>),
                                Header: "Corporate Action Summary",
                                columns: [
                                    {
                                        Header: "26D CFD Symbol",
                                        accessor: "productCode",
                                        width: 6 * this.state.vw,
                                    },
                                    {
                                        Header: "26D CFD Name",
                                        accessor: "description",
                                        width: 15 * this.state.vw,
                                    },
                                    {
                                        Header: "CFD Corporate Action",
                                        accessor: "action",
                                        width: 12 * this.state.vw,
                                    },

                                    {
                                        Header: "Ccy",
                                        accessor: "currencyCode",
                                        width: 4 * this.state.vw,
                                    },
                                    {
                                        Header: "Execution Date",
                                        accessor: "effectiveDate",
                                        width: 8 * this.state.vw,
                                    },
                                    {
                                        Header: "Rev.",
                                        accessor: "revision",
                                        width: 3 * this.state.vw,
                                    },
                                    {
                                        Header: "",
                                        Cell: <a style={{ fontWeight: "bold", color: "black" }}>DETAILS <i className="fa fa-angle-double-right"></i></a>,
                                        width: 5 * this.state.vw,
                                    },
                                ]
                            }

                        ]} defaultSorted={[
                            {
                                id: "effectiveDate",
                                desc: false
                            }
                        ]}
                        defaultPageSize={this.state.summaryRows === null ? 10 : this.state.summaryRows}
                        className="-striped -highlight summary"
                        getTrProps={(state, rowInfo) => {
                            if (rowInfo && rowInfo.row) {
                                return {
                                    onClick: (e) => {
                                        // TODO: verify here is good
                                        this.setDetailsState(rowInfo.original.actionId, this.props.token);
                                        this.setState({
                                            selected: rowInfo.index,
                                            reviewed: rowInfo.original.reviewed === 1
                                        })
                                    },
                                    style: {
                                        background: rowInfo.index === this.state.selected ?
                                            (rowInfo.original.reviewed === undefined ? 'rgba(0,0,0,0.15)' : rowInfo.original.reviewed === 1 ?
                                                'rgba(0,255,0,0.33)' : 'rgba(255,0,0,0.33)') :
                                            (rowInfo.original.reviewed === undefined ? 'white' : rowInfo.original.reviewed === 1 ?
                                                'rgba(0,255,0,0.15)' : 'rgba(255,0,0,0.15)'),
                                    }
                                }
                            } else {
                                return {}
                            }
                        }
                        }
                        getTheadProps={(state, rowInfo, column) => this.headerProps(state, rowInfo, column)}
                        getTheadGroupProps={(state, rowInfo, column) => this.headerProps(state, rowInfo, column)}
                        onPageSizeChange={(pageSize, pageIndex) => {
                            sessionStorage.setItem('summaryRows', pageSize);
                        }}
                    />
                    <br></br>
                    <br></br>
                    <ReactTable
                        data={details_data}
                        loading={this.state.loadingDetailed}
                        LoadingComponent={Loader}
                        columns={[
                            {
                                Header: "Details of Selected Corporate Action",
                                // render() {this.state.button},
                                columns: [
                                    {
                                        Header: "Description",
                                        accessor: "description",
                                        width: 12 * this.state.vw
                                    },
                                    {
                                        Header: "Value",
                                        accessor: "value",
                                        width: 16 * this.state.vw,
                                        getProps: (state, rowInfo, column) => {
                                            if (rowInfo && (rowInfo.row.description === 'IMPORTANT NOTE' || rowInfo.row.description === 'GUIDANCE')) {
                                                return {
                                                    style: {
                                                        border: rowInfo && rowInfo.row.value !== '-' ? '1.5pt red solid' : null,
                                                        // fontWeight: rowInfo && rowInfo.row.value != '-' ? 'bold' : null,
                                                    }
                                                };
                                            }
                                            return {}
                                        },
                                        Cell: this.defCell,
                                    },
                                    {
                                        Header: "dowc",
                                        accessor: "displayonwebclient",
                                        show: false,
                                    }
                                ],

                            }
                        ]}
                        defaultPageSize={10}
                        defaultSorted={[
                            {
                                id: "displayonwebclient",
                                desc: true
                            },
                            {
                                id: "description",
                                desc: false
                            }
                        ]}
                        className="details"
                        id="detail-table"
                        getTheadProps={(state, rowInfo, column) => this.headerProps(state, rowInfo, column)}
                        getTheadGroupProps={(state, rowInfo, column) => this.headerProps(state, rowInfo, column)}
                        getTrProps={(state, rowInfo) => {
                            if (rowInfo && rowInfo.row) {
                                return {
                                    style: {
                                        background: rowInfo.original.description === "IMPORTANT NOTE" || rowInfo.original.description === "GUIDANCE" ? 'rgba(255,0,0,0.33)' : 'white',
                                        fontWeight: rowInfo.original.displayonwebclient === 1 ? "bolder" : "normal",
                                    }
                                }
                            } else {
                                return {}
                            }
                        }
                        }

                        onPageSizeChange={(pageSize, pageIndex) => { this.setState({ detailsPageSize: pageSize }) }}

                    />

                </div>
            </div>
        );
    }

    defCell({ row, value, columnProps: { rest: { someFunc } } }) {
        if (row.description === "GUIDANCE") {
            return <p><Link to={"/corporate-actions/guide#dividend"} style={{ color: 'red', margin: '0' }}><u>Further Guidance</u></Link></p>
        }
        else {
            return <p style={{ margin: '0' }}>{value}</p>
        }

    }

    columnBorderProps(state, rowInfo) {
        return {
            style: {
                borderRight: '7px solid rgba(0,0,0,0.07)',
                borderLeft: '1px solid rgba(0,0,0,0.07)',
            }
        }
    }


    columnProps(state, rowInfo) {
        return {
            style: {
                borderRight: '1px solid rgba(0,0,0,0.07)',
                borderLeft: '1px solid rgba(0,0,0,0.07)',
            }
        }
    }

    rowProps(state, rowInfo, column) {
        return {
            style: {
                border: '1px solid rgba(0,0,0,0.07)',
            }
        }
    }

    // table setup helper
    headerProps(state, rowInfo, column) {
        return {
            style: {
                background: '#004b83',
                boxShadow: 'none',
                whiteSpace: 'pre-line !important',
                wordWrap: 'break-word',
            }
        }
    }

    groupHeaderProps(state, rowInfo, column) {
        return {
            style: {
                background: '#004b83',
                boxShadow: 'none',
            }
        }
    }

    CSVupload = (csv) => {
        if (csv !== null) {
            this.setState({
                loadingSummary: true
            })
            var symbols = [];
            if (typeof csv[0] == 'object') {
                for (var n = 0; n < csv.length; n++) {
                    symbols = symbols.concat(csv[n]);
                }
            } else {
                symbols = csv;
            }
            this.props.setAppSymbolState(symbols);
            getActionsBySymbols(symbols, moment(this.props.startDate).format('Y-MM-DD'), moment(this.props.endDate).format('Y-MM-DD'), this.props.token).then(res => {
                this.setState({
                    summary_data: res.data,
                    valid_symbols: res.valid,
                    details_data: [],
                    csv_imported: true,
                    csv_import_data: symbols,
                    loadingSummary: false
                })
            })
        }
    }

    csvError = (e) => {
        alert("ERROR! Please ensure you upload a valid file with 26D CFD symbol codes in a simple comma-separated format. For example: AAPL.NAS,TSLA.NAS,F.NYS")
    }

    searchFiltered = (e) => {
        var searchTerm = document.getElementById("searchTerm").value.toLowerCase();
        var searchResults = []
        var summary_data_before_search = this.state.summary_data_before_search;
        for (var i = 0; i < this.state.summary_data_before_search.length; i++) {
            if (summary_data_before_search[i].productCode.toLowerCase().includes(searchTerm) 
                || (summary_data_before_search[i].legacyProductCode ?? "").toLowerCase().includes(searchTerm)
                || summary_data_before_search[i].description.toLowerCase().includes(searchTerm) 
                || summary_data_before_search[i].action.toLowerCase().includes(searchTerm) 
                || summary_data_before_search[i].description.toLowerCase().includes(searchTerm) 
                || summary_data_before_search[i].currencyCode.toLowerCase().includes(searchTerm)) {
                searchResults.push(summary_data_before_search[i])
            }
        }
        this.setState({
            summary_data: searchResults,
            details_data: []
        })
    }

    authoriseCreateButton() {

        if (this.props.role === "IFS-Admin") {
            document.getElementById('admin-only').style.display = "block";
            if (document.getElementById('email-admin-only') !== null) {
                document.getElementById('email-admin-only').style.display = "block";
            }
        }
    }

    setDetailsState = (id, token) => {
        this.setState({
            loadingDetailed: true
        })
        if (token !== null) {
            let actionSummaryMap = new Map(
                this.state.summary_data.map(actionSummary => [actionSummary.actionId, actionSummary])
            );

            getDetails(id, token).then(res => {
                this.setState({
                    details_data: processDetailsState(actionSummaryMap.get(id), res[0].params),
                    id: id
                })
                this.authoriseCreateButton();
                this.setState({
                    loadingDetailed: false
                })
            })
        }

    }

    updateDates = (date) => {

        this.props.setAppDatesState(date[0], date[1]);
        this.filterData();

    }

    filterData() {
        this.setState({
            loadingSummary: true
        })
        var yesterday = dateToString(prevBusinessDate(new Date()));
        var yesterday_14 = dateToString(prevBusinessDateAdd14(new Date()));
        if (!(this.props.startDate === "" || this.props.endDate === "" || this.props.startDate === undefined || this.props.endDate === undefined)) {

            if (this.props.role === 'IFS-Staff' || this.props.role === 'IFS-Admin') {
                if (this.state.csv_imported) {
                    getActionsBySymbols(this.state.csv_import_data, moment(this.props.startDate).format('Y-MM-DD'), moment(this.props.endDate).format('Y-MM-DD'), this.props.token).then(res => {


                        this.setState({
                            summary_data: res.data,
                            valid_symbols: res.valid,
                            summary_data_before_search: res.data,
                            details_data: [],
                            loadingSummary: false
                        })
                    });
                } else {
                    getActionsByDateRange(moment(this.props.startDate !== null ? this.props.startDate : yesterday).format('Y-MM-DD'), moment(this.props.endDate !== null ? this.props.endDate : yesterday_14).format('Y-MM-DD'), this.props.token).then(res => {
                        this.setState({
                            summary_data: res,
                            summary_data_before_search: res,
                            details_data: [],
                            loadingSummary: false
                        })
                    })
                }
            }
            else if (this.props.role === 'IFS-Client') {
                if (this.state.csv_imported) {
                    getActionsBySymbols(this.state.csv_import_data, moment(this.props.startDate).format('Y-MM-DD'), moment(this.props.endDate).format('Y-MM-DD'), this.props.token).then(res => {


                        this.setState({
                            summary_data: res.data,
                            valid_symbols: res.valid,
                            summary_data_before_search: res.data,
                            details_data: [],
                            loadingSummary: false
                        })
                    });
                }
                else {
                    this.setState({
                        loadingSummary: false
                    })
                    getActionsByDateRange(moment(this.props.startDate !== null ? this.props.startDate : yesterday).format('Y-MM-DD'), moment(this.props.endDate !== null ? this.props.endDate : yesterday_14).format('Y-MM-DD'), this.props.token).then(res => {
                        this.setState({
                            summary_data: res,
                            summary_data_before_search: res,
                            details_data: [],
                            loadingSummary: false
                        })
                    })
                }
            }
        }
    }

    logout() {
        sessionStorage.clear();
        window.location.href = '/'
    }

    componentDidMount() {
        this.updateWindowDimensions();
        var al = {
            title: 'Disclaimer',
            message: `By clicking ‘Accept’, you agree that 26 Degrees supplies this 
            information on a best endeavours basis only based on the information 
            it has received as is, and will not be responsible or liable for any 
            inaccuracies, omissions or failures otherwise to provide information 
            on corporate actions. If you do not agree to this, please click 
            ‘Refuse’ and you will be returned to the homepage.`,
            closeOnClickOutside: false,
            customUI: ({ onClose }) => {
                return (
                    <div className='react-confirm-alert-body'>
                        <h1>DISCLAIMER</h1>
                        <p id="disclaimer">By clicking ‘Accept’, you agree that 26 Degrees supplies this
                        information on a best endeavours basis only based on the information
                        it has received as is, and will not be responsible or liable for any
                        inaccuracies, omissions or failures otherwise to provide information
                            on corporate actions.<br></br><br></br>If you do not agree to this, please click
                            ‘Refuse’ and you will be returned to the homepage.</p>
                        <div id="disc-button-container">
                            <button className="disclaimer-button" id="accept" onClick={() => {
                                sessionStorage.setItem("noDisclaimer", true);
                                this.filterData()
                                onClose();
                            }}>Accept</button>
                            <button className="disclaimer-button" id="refuse" onClick={() => this.logout()}>
                                Refuse
                  </button></div>
                    </div>
                )
            },
            closeOnEscape: false
        }
        if (sessionStorage.getItem("noDisclaimer") === null) {
            if (this.props.role === "IFS-Client") {
                confirmAlert(al)
            }
            else {
                this.setState({
                    loadingSummary: true
                })
            }
        }
        window.addEventListener('resize', this.updateWindowDimensions);


        if (this.props.role === 'IFS-Staff' || this.props.role === 'IFS-Admin') {
            this.filterData();
        }
        else if (this.props.role === 'IFS-Client' && sessionStorage.getItem("noDisclaimer") !== null) {
            this.setState({
                filters: { corp_type: ["Cash Dividend", "Acquisition", "Rights Offer", "Stock Split"] }
            })
            this.filterData();
        }
        this.CSVupload(this.props.symbols);
    }

    componentWillUnmount() {
        clearInterval(this.interval);
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions = () => {
        this.setState({ vw: window.innerWidth / 100, vh: window.innerHeight / 100 });
    }

    emailPopup() {
        getEmail(this.state.id, this.props.token).then(res=>{
            var al = {
                customUI: ({ onClose }) => {
                    let data = this.state.summary_data[this.state.selected]
                    let details = this.state.details_data.filter(d=>d.displayonwebclient===1)
                    return (
                        <div className='edit-user-container'>
                            <h4>Email notification</h4>
                            The following data will be sent:
                            <b>{data.action}: {data.productCode}</b>
                            <div>Effective date: {data.effectiveDate}</div>
                            <div>Currency: {data.currencyCode}</div>
                            {details.map(r => <div>{r.description}: {r.value}</div>)}
                            <br/>
                            {res.time!==undefined&&<div style={{color: 'red'}}>
                                Note: an email for this action has already been sent at {res.time}
                                </div>}
                            <button className="review-button-chilled" style={{ backgroundColor: "#004b83" }}
                                onClick={() => {
                                    sendEmailNotification({ action: data, params: details }, this.props.token)
                                        .then(() => { onClose() })
                                }}
                            >
                                <i class="far fa-envelope-open" style={{ marginRight: '0.75em' }} />
                                Send email notification
                            </button>
                        </div>
                    )
                },
                closeOnEscape: true
            }
            confirmAlert(al)
        })
        
    }

}

export default CorporateActionTool;