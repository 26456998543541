import React from 'react';
import PropTypes from 'prop-types';
import './newPasswordRequiredPage.css';


class NewPasswordRequiredForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      username: props.username,
      code: '',
      password: '',
      message: '',
      error: '',
    };
  }

  onSubmit = (event) => {
    event.preventDefault();
    this.props.onSubmit(this.state.password)
      .then(() => this.setState({
        message: 'Password reset',
        error: '',
      }))
      .catch((err) => this.setState({
        message: '',
        error: err.message,
      }));
  }


  changePassword = (event) => {
    this.setState({ password: event.target.value });
  }

  

  render = () => (
    <div>
    <div className="main">
    <link href="https://fonts.googleapis.com/css?family=Inter" rel="stylesheet" />
    <p className="passwordReset">Set New Password</p>
    <div className="error">{this.state.error}</div>
    <div className="message">{this.state.message}</div>
    <form className="form1" onSubmit={this.onSubmit}>
        <input placeholder="New Password" type="password" className="pass" onChange={this.changePassword} required />
      <button className="submitNewPassword" type="submit">Set new password</button>
    </form>
    </div>
    </div>
  )

  componentDidMount() {
  }

}
NewPasswordRequiredForm.propTypes = {
  onSubmit: PropTypes.func,
  error: PropTypes.string,
};

export default NewPasswordRequiredForm;
