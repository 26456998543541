import React from 'react';
import './sections.css';
import hubApp from './img/4.3-hub.png';

class OZHubApp extends React.Component {
    render = () => (
        <div>            
            <h2>4.0 oneZero Hub Configuration</h2>
            <h2>4.3 Settings - Hub App</h2>
            <ul>
                <li>
                    <p><b>Order Classification Settings</b></p>
                    <p>All orders that come through the Single Stock CFD MT5 Gateway must be configured with timeouts as shown below:</p>          
                    <ul type='disc'>
                        <li>Order Timeout: 40 s</li>
                        <li>Order Replace (Modify) Timeout: 40 s</li>
                        <li>Maker Response to Cancel Request Timeout: 20 s</li>
                        <li><span className='red-note'>Very Important</span></li>
                    </ul>      
                    <br></br>
                    <img src={hubApp}
                        alt="not found"
                        style={{
                            width: '70vw',
                            maxWidth: '1000px',
                            height:'auto',                                                        
                        }}
                    ></img>                      
                </li>
            </ul>
        </div>
    )
}

export default OZHubApp;