import React from 'react';

class Onboarding extends React.Component {
    render = () => (
        <div>
            <h2>2.0 Onboarding</h2>
            <h2 name="2.1">2.1 Legal Documents</h2>
            <p>Please ensure you have completed the 26 Degrees Global Data Agreement before looking to commence the technical integration.</p>
            
            <br></br>

            <h2 name="2.2">2.2 Operational Requirements for Onboarding</h2>
            <ol>
                <li>oneZero Hub</li>
                <li>Sentinel reporting tool</li>
            </ol>

            <br></br>

            <h2 name="2.3">2.3 oneZero Connection</h2>
            <p>Clients will need to request a new FIX API connection from oneZero which connects to 26 Degrees Global’s oneZero LD4 DMA CFD Hub. Please contact the 26 Degrees Prime Services & Client Coverage team to initiate this set-up: </p>
            <ul>
                <li>primeservices@invast.com.au</li>
                <li>clientcoverage@invast.com.au</li>
            </ul>

            <br></br>

            <h2 name="2.4">2.4 26 Degrees Documentation</h2>
            <p>Once the new API connection has been made, 26 Degrees will provide a copy of the following:</p>
            <ol>                
                <li>26 Degrees Symbol Specifications</li>
                <li>MT5 Symbol Config</li>
                <li>oneZero Symbol Config</li>
            </ol>
        
            <br></br>

            <h2 name="2.5">2.5 MT5 & oneZero Configuration</h2>
            <p>oneZero will work with you to ensure that settings on your MT5 server, MT5 gateway and oneZero hub have been configured as per the requirements in this guide.</p>

            <br></br>

            <h2 name="2.6">2.6 Account Funding</h2>
            <p>Prior to the commencement of live conformance testing, the Single Stock CFD account will need to be funded. Please contact our Client Coverage team if you need assistance.</p>
        </div>
    )
}

export default Onboarding;
