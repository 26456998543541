import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from 'react-router-dom';

import {
  Login,
  NewPasswordRequired,
  PasswordReset,
} from 'react-cognito';

import parseJwt from './logic/utilities.js';

import Home from './Home/';
// import Dashboard from './Dashboard'; 
import PasswordResetPage from './PasswordResetPage';
import CorporateActionGuide from './CorporateActionGuide';
import TechnicalGuide from './TechnicalGuide';
import Review from './Review/';
import Sorry from './Sorry/';
import CorporateActionTool from './CorporateActionTool';
import './App.css';
import Reporting from './Reporting';
import LoginPage from './LoginPage';
import NewPasswordRequiredPage from './NewPasswordRequiredPage';
import Navbar from './Navbar';
import './Navbar.css';
import Loader from './Loader';
import { connect } from 'react-redux';
import Admin from './Admin';
import Benchmarks from './Benchmarks';


const tool = (props, setAppSymbolState, symbols, setAppDatesState, startDate, endDate) => {
  if (props.cognitoState === "LOGGED_IN") {
    return (
      <div>
        <CorporateActionTool setAppSymbolState={setAppSymbolState} setAppDatesState={setAppDatesState} startDate={startDate} endDate={endDate} symbols={symbols} role={getUserGroup(props.cognitoUser.signInUserSession.idToken.jwtToken)} token={props.cognitoUser.signInUserSession.idToken.jwtToken} cognitoState={props.cognitoState} />
      </div>
    )
  }
  else if (props.cognitoState === "LOGGED_OUT") {
    return (
      <div>
        <Redirect to='/' />
      </div>
    )
  }
  else {
    return (
      <div>
        <div id="fullpage-loader">
          <Loader loading={true}></Loader>
        </div>
      </div>
    )
  }
}

const CAguide = (props) => {
  if (props.cognitoState === "LOGGED_IN") {
    return (
      <div>
        <CorporateActionGuide />
      </div>
    )
  }
  else if (props.cognitoState === "LOGGED_OUT") {
    return (
      <div>
        <Redirect to='/' />
      </div>
    )
  }
  else {
    return (
      <div>
        <div id="fullpage-loader">
          <Loader loading={true}></Loader>
        </div>
      </div>
    )
  }
}

const techGuide = (props) => {
  if (props.cognitoState === "LOGGED_IN") {
    return (
      <div>
        <TechnicalGuide token={props.cognitoUser.signInUserSession.idToken.jwtToken} />
      </div>
    )
  }
  else if (props.cognitoState === "LOGGED_OUT") {
    return (
      <div>
        <Redirect to='/' />
      </div>
    )
  }
  else {
    return (
      <div>
        <div id="fullpage-loader">
          <Loader loading={true}></Loader>
        </div>
      </div>
    )
  }
}

const reporting = (props) => {
  if (props.cognitoState === "LOGGED_IN") {
    return (
      <div>
        <Reporting />
      </div>
    )
  }
  else if (props.cognitoState === "LOGGED_OUT") {
    return (
      <div>
        <Redirect to='/' />
      </div>
    )
  }
  else {
    return (
      <div>
        <div id="fullpage-loader">
          <Loader loading={true}></Loader>
        </div>
      </div>
    )
  }
}

const prodInfo = (props) => {
  if (props.cognitoState === "LOGGED_IN") {
    return (
      <div>
        <Benchmarks role={getUserGroup(props.cognitoUser.signInUserSession.idToken.jwtToken)} token={props.cognitoUser.signInUserSession.idToken.jwtToken} cognitoState={props.cognitoState}/>
      </div>
    )
  }
  else if (props.cognitoState === "LOGGED_OUT") {
    return (
      <div>
        <Redirect to='/' />
      </div>
    )
  }
  else {
    return (
      <div>
        <div id="fullpage-loader">
          <Loader loading={true}></Loader>
        </div>
      </div>
    )
  }
}

const adminModule = (props) => {
  if (props.cognitoState === "LOGGED_IN" && (getUserGroup(props.cognitoUser.signInUserSession.idToken.jwtToken) === "IFS-Admin" || getUserGroup(props.cognitoUser.signInUserSession.idToken.jwtToken) === "IFS-Staff")) {
    return (
      <div>
        <Admin  role={getUserGroup(props.cognitoUser.signInUserSession.idToken.jwtToken)} token={props.cognitoUser.signInUserSession.idToken.jwtToken} />
      </div>
    )
  }
  else if (props.cognitoState === "LOGGED_OUT") {
    return (
      <div>
        <Redirect to='/' />
      </div>
    )
  }
  else if (props.cognitoState === "LOGGING_IN") {
    return (
      <div>
        <div id="fullpage-loader">
          <Loader loading={true}></Loader>
        </div>
      </div>
    )
  }
  else {
    return (
      <div>
        <Redirect to='/' />
      </div>
    )
  }
}

const resetPassword = () => {
  return (
    <div>
      <PasswordReset>
        <PasswordResetPage />
      </PasswordReset>
    </div>
  )
}

const review = (routeProps, props) => {
  if (props.cognitoState === "LOGGED_IN" && getUserGroup(props.cognitoUser.signInUserSession.idToken.jwtToken) === "IFS-Admin") {
    return (
      <div>
        <Review role={getUserGroup(props.cognitoUser.signInUserSession.idToken.jwtToken)} token={props.cognitoUser.signInUserSession.idToken.jwtToken} cognitoState={props.cognitoState} id={routeProps.location.id} action={routeProps.location.action} />
      </div>
    )
  }
  else if (props.cognitoState === "LOGGED_IN" && getUserGroup(props.cognitoUser.signInUserSession.idToken.jwtToken) !== "IFS-Admin") {
    return (
      <div>
        <Redirect to='/' />
      </div>
    )
  }
  else if (props.cognitoState === "LOGGED_OUT") {
    return (
      <div>
        <Redirect to='/' />
      </div>
    )
  }
  else {
    return (
      <div>
        <div id="fullpage-loader">
          <Loader loading={true}></Loader>
        </div>
      </div>
    )
  }
}

const NoMatch = ({ location }) => (
  <div><Redirect to='/sorry' /></div>
)

const homePage = (props) => {
  if (props.cognitoState === "LOGGED_OUT") {
    return (
      <div>
        <Login >
          <LoginPage />
        </Login>
      </div>
    )
  }
  else if (props.cognitoState === "LOGGED_IN") {
    return (
      <div>
        <Home role={getUserGroup(props.cognitoUser.signInUserSession.idToken.jwtToken)} token={props.cognitoUser.signInUserSession.idToken.jwtToken} cognitoState={props.cognitoState} />
      </div>
    )
  }
  else if (props.cognitoState === "NEW_PASSWORD_REQUIRED") {
    return (
      <div>
        {/* <p>New password required, since this is your first login</p> */}
        <NewPasswordRequired>
          <NewPasswordRequiredPage />
        </NewPasswordRequired>
      </div>
    )
  }
  else {
    return (
      <div>
        <div id="fullpage-loader">
          <Loader loading={true}></Loader>
        </div>
      </div>
    )
  }
}

// const emailVerificationPage = () => (
//   <div>
//     <Navbar />
//     <p>You must verify your email address. Please check your email for a code</p>
//     <EmailVerification>
//       <EmailVerificationForm />
//     </EmailVerification>
//   </div>
// );

// const confirmForm = () => (
//   <div>
//     <Navbar />
//     <p>A confirmation code has been sent to your email address</p>
//     <Confirm>
//       <ConfirmForm />
//     </Confirm>
//     <Link to="/">Home</Link>
//   </div>
// );

// const mfaPage = () => (
//   <div>
//     <p>You need to enter an MFA, but this library does not yet support them.</p>
//   </div>
// );




function getUserGroup(token) {
  if (token === undefined || token === null) {
    return null;
  }
  else {
    var groups = parseJwt(token)['cognito:groups'];
    if (groups===undefined) {
      return "IFS-Client";
    }
    let perm_groups = ['IFS-Client', 'IFS-Staff', 'IFS-Admin']
    // We want the group that is also in perm_groups
    return groups.filter(g=>perm_groups.includes(g))[0];
  }
}


class AppWithProvider extends Component {

  constructor(props) {
    super(props);
    this.state = {
      symbols: null,
      startDate: null,
      endDate: null
    }

  }

  setAppSymbolState(symbols) {
    this.setState({
      symbols: symbols,
    })
  }

  setAppDatesState(startDate, endDate) {
    this.setState({
      startDate: startDate,
      endDate: endDate
    })
  }



  render() {
    var setAppSymbolState = this.setAppSymbolState.bind(this);
    var setAppDatesState = this.setAppDatesState.bind(this);

    // console.log(this.props);

    return (
      <Router>
        <div>
          <Navbar firstName={this.props.cognitoAttributes["given_name"] === undefined ? "User" : this.props.cognitoAttributes["given_name"]} cognitoState={this.props.cognitoState} role={this.props.cognitoState === "LOGGED_IN" ? getUserGroup(this.props.cognitoUser.signInUserSession.idToken.jwtToken) : null} />
          <Switch>
            <Route exact path="/" render={() => homePage(this.props)} />
            <Route exact path="/corporate-actions/tool" render={() => tool(this.props, setAppSymbolState.bind(this), this.state.symbols, setAppDatesState.bind(this), this.state.startDate, this.state.endDate)} />
            <Route exact path="/corporate-actions/guide" render={() => CAguide(this.props)} />
            <Route exact path="/single-stocks/technical-guide" render={() => techGuide(this.props)} />
            <Route exact path="/single-stocks/reporting" render={() => reporting(this.props)} />
            <Route exact path="/user_management" render={() => adminModule(this.props)} />
            <Route exact path="/reset" render={() => resetPassword()} />
            <Route exact path="/single-stocks/product-info" render={() => prodInfo(this.props)} />
            {/* NEED TO PERFORM ROLE CHECK HERE TO CONDITIONALLY RENDER REVIEW PAGE */}
            <Route path="/review" render={(routeProps) => review(routeProps, this.props)} />

            <Route exact path="/sorry" component={Sorry} />
            <Route component={NoMatch} />
          </Switch>
        </div>
      </Router>
    );
  }

  // componentDidUpdate(prevProps) {

  //   if (this.props !== prevProps) {
  //     const token = getToken(this.props);

  //     this.setState({
  //         role: getUserGroup(token),
  //         token: token,
  //     })
  //   }

  // }

}

const mapStateToProps = state => ({
  cognitoState: state.cognito.state,
  cognitoUser: state.cognito.user,
  cognitoAttributes: state.cognito.attributes,
});

const App = connect(mapStateToProps, null)(AppWithProvider);

export default App;
