import React from 'react';

class Purpose extends React.Component {
    render = () => (
        <div>            
            <h2>1.0 Purpose of this Guide</h2>
            <p>The purpose of this guide is to assist our broker clients in applying the recommended settings in their MT5 server / MT5 Gateway and their oneZero Hub specifically for single Stock CFD trading with 26 Degrees.</p>            
            <br></br>
            <p>The settings suggested in this guide are for your guidance only and are presented on a best endeavours basis to optimise the infrastructure and workflows associated with data consumption and trade execution of single Stock CFDs with 26 Degrees.</p>
        </div>
    )
}

export default Purpose;
