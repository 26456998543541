import axios from 'axios';
// var baseRoute = process.env.REACT_APP_ENDPOINT_DPK + ":" + process.env.REACT_APP_ENDPOINT_DPK_PORT
// var baseRoute = "https://prickle-me-cactus.dev.invast.com.au";
var baseRoute = window.location.origin;
if (baseRoute.includes('localhost')) {
    baseRoute = "https://api.uat.26dgm.com/cactus-resource/v1/"
} else if (baseRoute === "https://prickle-me-cactus.dev.26dgm.com") {
    baseRoute = "https://api.dev.26dgm.com/cactus-resource/v1/"
} else if (baseRoute === "https://cactus.uat.26dgm.com") {
    baseRoute = "https://api.uat.26dgm.com/cactus-resource/v1/"
} else {
    baseRoute = "https://api.26degreesglobalmarkets.com/cactus-resource/v1/"
}

export const getDocument = (filename, token) => {
    var route = baseRoute + 'document/' + filename;
    return axios.get(route, {
        'headers': {
            'Authorization': token
        }
    })
        .then(res => {
            return res.data;
        })

}

export const presignedUrlPostDocument = (key,token) => {
    var route = baseRoute + 'document/' + key;
    return axios.put(route,{}, {
        'headers': {
            'Authorization': token,
        }
    })
        .then(res => {
            return res.data;
        })

}

export const uploadFile = (url,file,token) => {

    return axios.put(url, file).then(res => {
        return res;
    }).catch(err => { console.log(err) });
}

export const sendEmailNotification=(data, token) => {
    var route = baseRoute + 'email/CA/notification';
    return axios.post(route, data, {
        'headers': {
            'Authorization': token
        }
    }).then(res => {
        return res;
    })
}