import React from 'react';
import './home.css';
import { Link } from 'react-router-dom';

class Home extends React.Component {
  render = () => (
    <div>
      <div id="home-content">

        <div className="banner">
          <h2 className="headerText">SINGLE STOCK CFD BROKER PORTAL</h2>

        </div>
        <div id="home-cont">


          <Link to="/corporate-actions/tool">
            <div className="tile">
              {/* <div id="dashboard"></div> */}
              <div className="tile-content base" >

                <h2>Corporate<br/>Action Tool</h2>
                <h3>Access all corporate actions relevant to your Single Stock CFD client holdings in MT5</h3>

              </div>
            </div>
          </Link>

          <div className="divider"/>

          <Link to="/corporate-actions/guide">
            <div className="tile">

              <div className="tile-content base">
                <h2>Corporate<br/>Action Guide</h2>
                <h3>Access information about the corporate actions that may apply to your clients holdings and learn how to apply these in your MT5</h3>
              </div>
            </div>
          </Link>

        </div>
        <div id="home-cont">
          <div className="divider-vertical"/>
          <div className="divider-spacer"/>
          <div className="divider-vertical"/>
        </div>
        <div id="home-cont">

          <Link to="/single-stocks/technical-guide">
            <div className="tile">
              <div className="tile-content base">
                <h2>Technical Guide</h2>
                <h3>Access a guide to assist you in applying the recommended settings to your MT5 Server, Gateway and oneZero Hub for Single Stock CFD trading with 26 Degrees</h3>
              </div>
            </div>

          </Link>

          <div className="divider"/>

          <Link to="/single-stocks/reporting">
            <div className="tile">

              <div className="tile-content base">
                <h2>Market Data<br/>Reporting</h2>
                <h3>Upload and declare your client's consumption of Exchange Market Data</h3>
              </div>

            </div>
          </Link>
        </div>
      </div>
    </div>
  )

  componentDidMount() {

  }

}


export default Home;