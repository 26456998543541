import React, { Component } from 'react';
import { presignedUrlPostDocument, uploadFile } from '../../logic/queryAWSAPI.js'
import './fileUpload.css'
import { postChangelogMessage } from '../../logic/queryAPI.js'

class FileUpload extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        if (this.props.role === "IFS-Admin") {
            return (
                <div className="wrapper">
                    <div id="error-text"></div>
                    <form id="upload-form">
                        <select id="filedisplayname">
                            <option disabled selected>Please select a file to upload</option>
                            <option value="InvastGlobal-MT5-SingleStocks.zip">MT5 Single Stocks (zip containing json)</option>
                            <option value="InvastGlobal-OZ-SessionProfiles.csv">oneZero Session Profiles</option>
                            <option value="InvastGlobal-OZ-SymbolSettings.csv">oneZero Symbol Settings</option>
                        </select>
                        <br></br>
                    </form>
                    <h5>Changelog message (REQUIRED):</h5>
                    <p>Note: This message will be viewable to clients in Technical Guide Section 6</p>
                    <textarea rows="4" cols="50" id="changelog-msg" form="upload-form"></textarea>
                    <br></br>
                    <br></br>
                    <label className="button" htmlFor="fileUpload">Upload file</label>
                    <input type="file" id="fileUpload" className="file" name="file" onChange={(e) => this.handleChangeFile(document.getElementById('filedisplayname').value, e)} />
                    <br></br>
                    <div id="upload-note"></div>
                </div>
            );
        }
        else {
            return <div><h1>Unauthorised</h1></div>
        }
    }

    handleChangeFile(filename, event) {
        if (document.getElementById("changelog-msg").value === "") {
            document.getElementById("error-text").innerHTML = 'Failed: Changelog message required'
        }
        else if (document.getElementById("filedisplayname").value === "Please select a file to upload") {
            document.getElementById("error-text").innerHTML = 'Failed: File selection required'
        }
        else {
            var file = event.target.files[0];
            presignedUrlPostDocument(filename, this.props.token).then(res => {
                uploadFile(res.data.documentUrl, file, this.props.token).then(res => {
                    postChangelogMessage({
                        "data": {
                            "filename": filename,
                            "message": document.getElementById("changelog-msg").value
                        }
                    }, this.props.token).then(res => {
                        if (res==="Success") {
                            document.getElementById('upload-note').innerHTML = "Upload successful"
                        } else {
                            document.getElementById('upload-note').innerHTML = "Upload failed"
                        }
                    })
                });
            })
        }
    }

}

export default FileUpload