import React from 'react';
import PropTypes from 'prop-types';
import './passwordResetPage.css';

class PasswordResetForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      username: props.username,
      code: '',
      password: '',
      message: '',
      error: '',
    };
  }

  onSubmit = (event) => {
    event.preventDefault();
    this.props.setPassword(this.state.username, this.state.code, this.state.password)
      .then(() => this.setState({
        message: 'Password reset',
        error: '',
      }))
      .catch((err) => this.setState({
        message: '',
        error: err.message,
      }));
  }

  sendVerificationCode = (event) => {
    event.preventDefault();
    this.props.sendVerificationCode(this.state.username)
      .then(() => this.setState({
        message: 'Verification code sent',
        error: '',
      }))
      .catch((err) => {
        if (err.code === 'UserNotFoundException') {
          this.setState({ error: 'User not found' });
        } else {
          this.setState({ error: err.message })
        }
      });
  }

  changePassword = (event) => {
    this.setState({ password: event.target.value });
  }

  changeCode = (event) => {
    this.setState({ code: event.target.value });
  }

  changeUsername =(event) => {
    this.setState({ username: event.target.value });
  }

  render = () => (
    <div>
      <div className="main">
        <link href="https://fonts.googleapis.com/css?family=Inter" rel="stylesheet" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <p className="passwordReset">Password Reset</p>
        <div className="error">{this.state.error}</div>
        <div className="message">{this.state.message}</div>
        <form className="form1" onSubmit={this.sendVerificationCode}>
        <input className="un" type="text" placeholder="Username" value={this.state.username} onChange={this.changeUsername} required />
        <input className='submitVerif' type='submit' value='Send Verification Code' />
        </form>
        <form className="form2" onSubmit={this.onSubmit}>
        <input className="un" placeholder="Verification Code" onChange={this.changeCode} required />
        <input className="pass" type="password" placeholder="New Password" onChange={this.changePassword} required />
        <input className='submitNewPassword' type='submit' value='Set New Password' />
        </form>
      </div>
    </div>
  )

  componentDidMount() {
  }

}
PasswordResetForm.propTypes = {
  error: PropTypes.string,
  username: PropTypes.string,
  sendVerificationCode: PropTypes.func,
  setPassword: PropTypes.func,
};
export default PasswordResetForm;
