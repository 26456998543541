import React, { Component } from 'react';
import Logo from './images/26d-logo.png';
import WhiteLogo from './images/26d-logo-white.png';
import { NavLink } from 'react-router-dom';
import { Logout } from 'react-cognito';
import LogoutButton from './LogoutButton';


// Link to Home ... from 26 Degrees logo
// always shown
const linkLogo = () => {
    return (
        <NavLink className="Navbar-logo" alt="Corp Actions" exact to="/">  <img src={WhiteLogo} onMouseOver={e => (e.currentTarget.src = Logo)} onMouseOut={e => (e.currentTarget.src = WhiteLogo)} alt="26 Degrees Logo" className="Navbar-logo"></img></NavLink>
    );
}

// Link to Home
// always shown
const linkHome = () => {
    return (
        <NavLink exact to="/"
            className="Navbar-link"
            activeClassName="Navbar-active">
            Home
        </NavLink>
    );
}

// Link to Log Out
// shown when logged in
const linkUser = (firstName) => {
    return (
        <div
            className="Navbar-link-fixed-dropdown">
            <div className="fixed-dropdown">
                <i className="fas fa-caret-down"></i>
                <div style={{marginLeft: '5px'}}>{firstName}</div>
            </div>
            <div className="fixed-dropdown-content">
                <a className="Navbar-link" href="/reset">Reset Password</a>
                <a className="Navbar-link">
                    <Logout>
                        <LogoutButton></LogoutButton>
                    </Logout>
                </a>
            </div>
        </div>
    );
}

// Drop down to corporate action links
// shown when logged in
const linkCorporateActions = () => {
    return (
        <div className="Navbar-link-dropdown">
            <div className="drop-link-button">
                <i className="fas fa-caret-down"></i>
                {" Corporate Actions"}
            </div>
            <div className="drop-link-content">
                <NavLink exact to="/corporate-actions/guide"
                    className="Navbar-link"
                    activeClassName="Navbar-active"
                >
                    Guide
                </NavLink>
                <NavLink exact to="/corporate-actions/tool"
                    className="Navbar-link"
                    activeClassName="Navbar-active"
                >
                    Tool
                </NavLink>
            </div>
        </div>
    );
}

// Drop down to single stock DMA links
// shown when logged in
const linkSingleStocks = () => {
    return (
        <div className="Navbar-link-dropdown">
            <div className="drop-link-button">
                <i className="fas fa-caret-down"></i>
                {" Single Stocks"}
            </div>
            <div className="drop-link-content">
                <NavLink exact to="/single-stocks/technical-guide"
                    className="Navbar-link"
                    activeClassName="Navbar-active"
                >
                    Technical Guide
                </NavLink>
                <NavLink exact to="/single-stocks/reporting"
                    className="Navbar-link"
                    activeClassName="Navbar-active"
                >
                    Reporting
                </NavLink>
                <NavLink exact to="/single-stocks/product-info"
                    className="Navbar-link"
                    activeClassName="Navbar-active"
                >
                    Currency Benchmarks
                </NavLink>
            </div>
        </div>
    );
}

// Link to User Management
// Shown When logged in and group is IFS-Admin [TO BE IMPLEMENTED]
// Link to Reporting Form
// shown when logged int
const linkUserManagement = () => {
    return (
        <NavLink exact to="/user_management"
            className="Navbar-link"
            activeClassName="Navbar-active">
            Admin
        </NavLink>
    );
}

const linkLogIn = () => {
    return (
        <NavLink exact to="/"
            className="Navbar-link"
            activeClassName="Navbar-active">
            Login
        </NavLink>
    );
}

class Navbar extends Component {
    render() {
        // console.log(this.props.firstName)
        if (this.props.cognitoState === "LOGGED_IN") {
            return (
                <div className="Navbar">
                    {linkLogo()}
                    <div className="Navbar-spacer"></div>
                    {linkHome()}
                    {linkCorporateActions()}
                    {linkSingleStocks()}
                    {(this.props.role === "IFS-Admin" || this.props.role === "IFS-Staff") ? linkUserManagement() : null}
                    {linkUser(this.props.firstName)}
                </div>
            )
        }
        else if (this.props.cognitoState === "LOGGED_OUT") {
            return (
                <div className="Navbar">
                    {linkLogo()}
                    <div className="Navbar-spacer"></div>
                    {linkLogIn()}
                </div>
            )
        }
        else {
            return (
                <div className="Navbar">
                    {linkLogo()}
                    <div className="Navbar-spacer"></div>
                </div>
            )
        }

    }

}

export default Navbar;
