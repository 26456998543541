import React from 'react';
import './reporting.css';

class ReportingForm extends React.Component {
    render = () => (
        <div style={{display:'flex',justifyContent:'center'}}>
            <a name="form1171609428" id="formAnchor1171609428"></a>
        </div>
    )

    scriptLoaded() {
        window.EmbedManager.embed({
            key: "https://fs22.formsite.com/res/showFormEmbed?EParam=m_OmK8apOTBIxKg2CBZAxN-nxl0gotM_K5oMHUOxJy8&1171609428",
            width: "75%"
        });
    }

    componentDidMount() {
        const script = document.createElement("script");
        script.src = "https://fs22.formsite.com/include/form/embedManager.js?1171609428";
        script.async = true;
        script.onload = this.scriptLoaded;
        document.body.appendChild(script);
    }
}
export default ReportingForm;