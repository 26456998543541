import React, { Component } from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import './userManagement.css'
import { disableUser, resetUserPassword, resetUserAccount, updateExistingAttributes, matchUserGroups, createNewUser, listUsersGroup, getGroupOfUser } from '../../logic/userManagementAPI.js'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Loader from '../../Loader.jsx';

class UserManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            users: [],
            usersBeforeSearch: [],
            clicked: null
        }
    }

    render() {

        const data = this.state.users;

        return (
            <div className="wrapper">
                <div className="searchUserManagement">
                    <input type="text" className="searchTerm" id="searchTerm" placeholder="Search by First/Last Name, Username or Email" onChange={(e) => this.searchFiltered(e)} />
                    <button className="searchButton" >
                        <i className="fa fa-search"></i>
                    </button>
                </div>
                <p id="api-message" style={{ textAlign: "center" }}></p>
                <div className="top-buttons-container">
                    <button className="user-buttons" id="create-new-user" onClick={() => this.createNewUser(this.props.role)}>Create New User</button>
                    <button className="user-buttons" id="refresh-table" onClick={() => this.updateData()}>Refresh Table</button>
                </div>
                <div className="tableHolderUserManagement">
                    <ReactTable
                        data={data}
                        loading={this.state.loading}
                        LoadingComponent={Loader}
                        noDataText="Sorry, we couldn't find any users... You should try to refresh and if that fails, annoy the IT team by showing them this message!"
                        columns={[
                            {
                                Cell: (<button>Edit</button>),
                                Header: "Enabled Users and their Respective Groups",
                                columns: [
                                    {
                                        Header: "Username",
                                        accessor: "username",
                                        width: 10 * this.state.vw,
                                    },
                                    {
                                        Header: "First Name",
                                        accessor: "firstName",
                                        width: 8 * this.state.vw,
                                    },
                                    {
                                        Header: "Last Name",
                                        accessor: "lastName",
                                        width: 8 * this.state.vw,
                                    },
                                    {
                                        Header: "Email",
                                        accessor: "email",
                                        width: 10 * this.state.vw,
                                    },
                                    {
                                        Header: "Status",
                                        accessor: "status",
                                        width: 6 * this.state.vw,
                                    },
                                    {
                                        Header: "Receive Notifications?",
                                        // accessor: 'groups',
                                        width: 6 * this.state.vw,
                                        Cell: (rowInfo) => rowInfo.original.groups.includes("IFS-Client-CA-Notification") ? 'Yes' : 'No'
                                    },
                                    {
                                        Header: "User Admin",
                                        width: 8 * this.state.vw,
                                        Cell: (rowInfo) => this.userAdminCell(rowInfo, this.props.role),
                                        getProps: (state, rowInfo, column) => {
                                            return {
                                                style: {
                                                    textAlign: "center",
                                                    padding: "0px",
                                                }
                                            }
                                        },
                                    },
                                ]
                            }

                        ]}

                        defaultPageSize={20}
                        className="-striped -highlight summary"
                        getTheadProps={(state, rowInfo, column) => this.headerProps(state, rowInfo, column)}
                        getTheadGroupProps={(state, rowInfo, column) => this.headerProps(state, rowInfo, column)}
                    />

                </div>

            </div>
        );
    }

    createNewUser(role) {
        var al = {
            customUI: ({ onClose }) => {
                return (
                    <div className='edit-user-container'>
                        <div style={{ size: "50px", cursor: "pointer" }} className="far fa-window-close" onClick={() => onClose()}>  Esc to cancel</div>
                        <h1>Create a new user:</h1>
                        <div className="edit-user-col-container">
                            <div className="edit-user-col1">
                                <label htmlFor="username">Username</label>
                                <input type="text" name="username" id="username" className="edit-user-text-input" tabIndex="1" /> <br></br><br></br>
                                <label htmlFor="firstname">First Name</label>
                                <input type="text" name="firstname" id="firstname" className="edit-user-text-input" tabIndex="3" />
                                <div id="groups">
                                    <p>User Groups:</p>
                                    <ul>
                                        <li>
                                            <input type="radio" name="ifs-role" id="ifs-client" className="edit-user-text-input" selected />
                                            <label htmlFor="ifs-client" tabIndex="5">IFS-Client</label>
                                        </li>
                                        <li>
                                            <input type="radio" name="ifs-role" id="ifs-staff" className="edit-user-text-input" />
                                            <label htmlFor="ifs-staff" tabIndex="6">IFS-Staff</label>
                                        </li>
                                        <li>
                                            <input type="radio" name="ifs-role" id="ifs-admin" className="edit-user-text-input" />
                                            <label htmlFor="ifs-admin" tabIndex="7">IFS-Admin</label>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="edit-user-col2">
                                <label htmlFor="email">Email</label>
                                <input type="text" name="email" id="email" className="edit-user-text-input" tabIndex="2" /><br></br><br></br>
                                <label htmlFor="lastname">Last Name</label>
                                <input type="text" name="lastname" id="lastname" className="edit-user-text-input" tabIndex="4" />
                            </div>
                        </div>
                        <div className="edit-user-col-container">
                                <div>
                                    <label htmlFor="newNotificationCheckbox">Receive Corporate Action Notifications?</label>
                                    <input type="checkbox" id="newNotificationCheckbox" name="newNotificationCheckbox" />
                                </div>
                            </div>
                        <div id="disc-button-container">
                            <div>
                                <button className="user-buttons" id="create-new-user" onClick={(event) => {
                                    createNewUser(document.getElementById("username").value, document.getElementById("email").value, document.getElementById("firstname").value, document.getElementById("lastname").value, this.props.token).then(res => {
                                        var groupsToAdd = [];
                                        if (document.getElementById("newNotificationCheckbox").checked) {
                                            groupsToAdd.push("IFS-Client-CA-Notification")
                                        }
                                        if (document.getElementById("ifs-client").checked || !(document.getElementById("email").value.endsWith('@26degreesglobalmarkets.com') || document.getElementById("email").value.endsWith('@26dgm.com'))) {
                                            groupsToAdd.push("IFS-Client");
                                        }
                                        if (document.getElementById("ifs-staff").checked && (document.getElementById("email").value.endsWith('@26degreesglobalmarkets.com') || document.getElementById("email").value.endsWith('@26dgm.com'))) {
                                            groupsToAdd.push("IFS-Staff");
                                        }
                                        if (document.getElementById("ifs-admin").checked && (document.getElementById("email").value.endsWith('@26degreesglobalmarkets.com') || document.getElementById("email").value.endsWith('@26dgm.com'))) {
                                            groupsToAdd.push("IFS-Admin");
                                        }
                                        matchUserGroups(document.getElementById("username").value, groupsToAdd, this.props.token);
                                        this.updateData();
                                        onClose();
                                    }
                                    )
                                }}>Create User</button>
                            </div>
                        </div>
                    </div>
                )
            },
            closeOnEscape: true
        }

        var alUnauth = {
            customUI: ({ onClose }) => {
                return (
                    <div className='edit-user-container'>
                        <div style={{ size: "50px", cursor: "pointer" }} className="far fa-window-close" onClick={() => onClose()}>  Esc to cancel</div>
                        <h1>Unauthorised</h1>
                    </div>
                )
            },
            closeOnEscape: true
        }
        role === 'IFS-Admin' ? confirmAlert(al) : confirmAlert(alUnauth);
    }

    editUser(rowFields) {

        let groups;

        getGroupOfUser(this.props.token, rowFields.username).then(res => {
            if (res !== undefined) {
                groups = res.data.groups;
            }
            else {
                groups = "Error: could not find group";
            }

            let perm_groups = ['IFS-Client', 'IFS-Staff', 'IFS-Admin']
            // We want the group that is also in perm_groups
            let group = groups.filter(g => perm_groups.includes(g))[0];

            var al = {
                customUI: ({ onClose }) => {
                    return (
                        <div className='edit-user-container'>
                            <div style={{ size: "50px", cursor: "pointer" }} className="far fa-window-close" onClick={() => onClose()} id="edit-close">  Esc to cancel</div>
                            <h3>Editing User: {rowFields.username}</h3>
                            <h4 id="h4-nopad">Group: {group}</h4>
                            <div className="edit-user-col-container">
                                <div className="edit-user-col1">
                                    <label htmlFor="firstname">First Name</label>
                                    <input type="text" name="firstname" id="firstname" className="edit-user-text-input" defaultValue={rowFields.firstName} />
                                </div>
                                <div className="edit-user-col2">
                                    <label htmlFor="lastname">Last Name</label>
                                    <input type="text" name="lastname" id="lastname" className="edit-user-text-input" defaultValue={rowFields.lastName} />
                                </div>
                            </div>
                            <br />
                            <div className="edit-user-col-container">
                                <div>
                                    <label htmlFor="updateNotificationCheckbox">Receive Corporate Action Notifications?</label>
                                    <input type="checkbox" id="updateNotificationCheckbox" name="updateNotificationCheckbox" defaultChecked={groups.includes("IFS-Client-CA-Notification")} />
                                </div>
                                 
                                
                            </div>
                            <div className="edit-user-col-container" style={{fontSize: '0.75em'}}>(Note: please click 'Submit' to confirm changes)</div>
                            <div id="user-button-container">
                                <div className="user-button-cont-row">
                                    <button className="user-buttons" id="submit-user-changes" onClick={(event) => { this.submitUserChanges(rowFields, groups); onClose(); }}>Submit User Changes</button>
                                    <button className="user-buttons" id="reset-user-password" onClick={(event) => { resetUserPassword(rowFields.username, this.props.token).then(res => { this.updateData(); onClose(); }) }}>Reset User Password</button>
                                </div>
                                <div className="user-button-cont-row">
                                    <button className="user-buttons" id="reset-user-account" onClick={(event) => { this.controlResetAccountButton(rowFields.username, this.props.token) }}>Resend Portal Invitation</button>
                                    <button className="user-buttons" id="disable-user" onClick={(event) => { this.controlDisableButton(rowFields.username, this.props.token) }}>Disable User</button>
                                </div>
                            </div>
                        </div>
                    )
                },
                closeOnEscape: true
            }

            confirmAlert(al);

        })


    }

    controlDisableButton(username, token) {
        if (document.getElementById('disable-user').innerHTML === "Disable User") {
            document.getElementById('disable-user').innerHTML = "Click Again to Confirm";
        }
        else if (document.getElementById('disable-user').innerHTML === "Click Again to Confirm") {
            disableUser(username, token).then(res => {
                this.updateData();
                document.getElementById('edit-close').click();
            })
        }
    }

    controlResetAccountButton(username, token) {
        if (document.getElementById('reset-user-account').innerHTML === "Resend Portal Invitation") {
            document.getElementById('reset-user-account').innerHTML = "Click Again to Confirm";
        }
        else if (document.getElementById('reset-user-account').innerHTML === "Click Again to Confirm") {
            resetUserAccount(username, token).then(res => {
                this.updateData();
                document.getElementById('edit-close').click();
            })
        }
    }

    submitUserChanges(rowFields, groups) {

        if (rowFields.firstName !== document.getElementById("firstname").value || rowFields.lastName !== document.getElementById("lastname").value) {
            updateExistingAttributes(rowFields.username, document.getElementById("firstname").value, document.getElementById("lastname").value, this.props.token).then(res => { this.updateData() });
        }
        if (groups.includes('IFS-Client-CA-Notification') !== document.getElementById('updateNotificationCheckbox').checked) {
            if (document.getElementById('updateNotificationCheckbox').checked) {
                groups.push('IFS-Client-CA-Notification')
            } else {
                groups = groups.filter(g=>g!=='IFS-Client-CA-Notification')
            }
            matchUserGroups(rowFields.username, groups, this.props.token).then(this.updateData())
        }
    }


    userAdminCell(rowInfo, role) {

        return role === 'IFS-Admin' ? <button className="edit-user-button" onClick={() => this.editUser(this.state.users[rowInfo.index])}>Edit User</button> : <div>Unauthorised</div>;


    }

    columnBorderProps(state, rowInfo) {
        return {
            style: {
                borderRight: '7px solid rgba(0,0,0,0.07)',
                borderLeft: '1px solid rgba(0,0,0,0.07)',
            }
        }
    }


    columnProps(state, rowInfo) {
        return {
            style: {
                borderRight: '1px solid rgba(0,0,0,0.07)',
                borderLeft: '1px solid rgba(0,0,0,0.07)',
            }
        }
    }

    rowProps(state, rowInfo, column) {
        return {
            style: {
                border: '1px solid rgba(0,0,0,0.07)',
            }
        }
    }

    // table setup helper
    headerProps(state, rowInfo, column) {
        return {
            style: {
                background: '#004b83',
                boxShadow: 'none',
                whiteSpace: 'pre-line !important',
                wordWrap: 'break-word',
                // border: '1px solid rgba(0,0,0,0.07)'              
            }
        }
    }

    groupHeaderProps(state, rowInfo, column) {
        return {
            style: {
                background: '#004b83',
                boxShadow: 'none',

                // borderLeft: '7px solid rgba(0,0,0,0.07)',
                // borderRight: '7px solid rgba(0,0,0,0.07)'
            }
        }
    }

    searchFiltered = (e) => {
        var searchTerm = document.getElementById("searchTerm").value.toLowerCase();
        var searchResults = [];
        var usersBeforeSearch = this.state.usersBeforeSearch;

        for (var i = 0; i < this.state.usersBeforeSearch.length; i++) {
            if (usersBeforeSearch[i].username === undefined || usersBeforeSearch[i].email === undefined) {
                break;
            }
            if (usersBeforeSearch[i].username.toLowerCase().includes(searchTerm) || usersBeforeSearch[i].email.toLowerCase().includes(searchTerm) || usersBeforeSearch[i].firstName.toLowerCase().includes(searchTerm) || usersBeforeSearch[i].lastName.toLowerCase().includes(searchTerm)) {
                searchResults.push(usersBeforeSearch[i])
            }
        }
        this.setState({
            users: searchResults,
        })
    }


    componentDidMount() {
        this.updateData();
    }

    updateData() {
        this.setState({
            loading: true
        })
        listUsersGroup(this.props.token).then(res => {
            if (res !== undefined) {
                this.setState({
                    users: res.data,
                    usersBeforeSearch: res.data,
                    loading: false
                })
            }
            else {
                this.setState({
                    loading: false
                })
            }

        })
    }

}

export default UserManagement