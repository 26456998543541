import React from 'react';

import './sections.css';

import symbols from './img/3.2-symbols.png';
import timeouts from './img/3.2-timeouts.png';

class MT5Gateways extends React.Component {
    render = () => (
        <div>            
            <h2>3.0 MT5 Server Configuration</h2>
            <h2>3.2 Gateways</h2>
            <p>The oneZero MT5 Gateway settings will control the optimization, pricing, and execution for the single Stock CFDs provided by 26 Degrees. oneZero recommends a separate <b>oneZero MT5 Gateway</b> for 26 Degrees’s single Stock CFDs.</p>                                    
            <p><b>Gateway Settings</b></p>
            <ul>
                <li>
                    <b>Name: </b> oneZero MT5 Gateway
                </li>
                <li>
                    <b>Common: </b>                    
                    <p>- This will be configured by oneZero and should be setup for Trade and Quotes to ensure pricing and execution through the Gateway.</p>
                </li>
                <li>
                    <b>Groups: </b>                    
                    <p>- Groups control which clients are pricing & executing through this Gateway.</p>
                </li>
                <li>
                    <b>Symbols</b>                    
                    <p>- Clients should configure one single 'overarching' single stock CFD symbol group as shown below. The client may choose to add sub-categories under this Symbol group to segment for Market Data.</p>
                    <img src={symbols}
                        alt="not found"
                        style={{
                            width: '50vw',
                            maxWidth: '700px',
                            height:'auto',                                                        
                        }}
                    ></img>
                </li>
                <li>
                    <p><b>Parameters</b></p>
                    <table className='mt5-gateways'
                        style={{
                            width: '60vw',
                            maxWidth: '600px'
                        }}
                    >
                        <thead>
                        <tr>
                            <th>Parameter</th><th>Value</th><th>Meaning</th>                        
                        </tr>
                        </thead>
                        <tbody>                        
                        <tr>
                            <td>ExecutionMarketFOK</td><td>0</td><td>MarketGTC</td>
                        </tr>
                        <tr>
                            <td>ExecutionMarketIOC</td><td>0</td><td>MarketGTC</td>
                        </tr>
                        <tr>
                            <td>ExecutionLimitFOK</td><td>2</td><td>LimitGTC</td>
                        </tr>
                        <tr>
                            <td>ExecutionLimitIOC</td><td>2</td><td>LimitGTC</td>
                        </tr>
                        <tr>
                            <td>ExecutionInstantFOK</td><td>0</td><td>MarketGTC</td>
                        </tr>
                        <tr>
                            <td>ExecutionInstantIOC</td><td>0</td><td>MarketGTC</td>
                        </tr>
                        <tr>
                            <td>ExecutionStopFOK</td><td>0</td><td>MarketGTC</td>
                        </tr>
                        <tr>
                            <td>ExecutionStopIOC</td><td>0</td><td>MarketGTC</td>
                        </tr>
                        <tr>
                            <td>ExecutionExchangeFOK</td><td>0</td><td>MarketGTC</td>
                        </tr>
                        <tr>
                            <td>ExecutionExchangeIOC</td><td>0</td><td>MarketGTC</td>
                        </tr>
                        <tr>
                            <td>KeepPartiallyFilledPendingOrdersOpen</td><td>1</td><td>Partially Filled Orders stay Open</td>
                        </tr>
                        <tr>
                            <td>InvertedSpreadBehavior</td><td>2</td><td>Blocks Inverted Spreads</td>
                        </tr>
                        <tr>
                            <td>RoundExecPriceToDigits</td><td>0</td><td>Disables Rounding of Executed Price</td>
                        </tr>
                        </tbody>
                    </table>
                </li>
                <li>
                    <p><b>Timeouts</b></p>
                    <img src={timeouts}
                        alt="not found"
                        style={{
                            width: '50vw',
                            maxWidth: '700px',
                            height:'auto',                                                        
                        }}
                    ></img>
                </li>
            </ul>
        </div>
    )
}

export default MT5Gateways;