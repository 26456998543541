import axios from 'axios';
// var baseRoute = process.env.REACT_APP_ENDPOINT_DPK + ":" + process.env.REACT_APP_ENDPOINT_DPK_PORT
// var baseRoute = "https://prickle-me-cactus.dev.invast.com.au";
var baseRoute = window.location.origin;
if (baseRoute.includes('localhost')) {
    baseRoute = "https://api.dev.26dgm.com/cactus-resource/v1/user/"
} else if (baseRoute === "https://prickle-me-cactus.dev.26dgm.com") {
    baseRoute = "https://api.dev.26dgm.com/cactus-resource/v1/user/"
} else if (baseRoute === "https://cactus.uat.26dgm.com") {
    baseRoute = "https://api.uat.26dgm.com/cactus-resource/v1/user/"
} else {
    baseRoute = "https://api.26degreesglobalmarkets.com/cactus-resource/v1/user/"
}

export const createNewUser = (username, email, firstName, lastName, token) => {
    var route = baseRoute;
    return axios.post(route,{
        "username": username,
        "email": email,
        "password": "",
        "firstName": firstName,
        "lastName": lastName,
        },
        {
        'headers': {
            'Authorization': token,
        }
    })
        .then(res => {
            document.getElementById("api-message").innerHTML = res.data.status + ": " + res.data.message;
            document.getElementById("api-message").style.color = "black";
            return res.data;
        })
        .catch(err => {
            if (err.response) {
                // Reuest was made and server responded with status code outside of 2xx range
                document.getElementById("api-message").innerHTML = err.response.data.status + ": " + err.response.data.message;
              } else if (err.request) {
                // The request was made but no response was received
                document.getElementById("api-message").innerHTML = err.message;
              } else {
                // Something happened in setting up the request that triggered an Error
                document.getElementById("api-message").innerHTML ='Error' + err.message;
              }
            document.getElementById("api-message").style.color = "red";
        })

}

export const resetUserPassword = (username,token) => {
    var route = baseRoute + username + '/reset-password';
    return axios.post(route, {},
        {
        'headers': {
            'Authorization': token
        }
    })
        .then(res => {
            document.getElementById("api-message").innerHTML = "Reset user password successfully.";
            document.getElementById("api-message").style.color = "black";
            return res;
        }).catch(err => {
            if (err.response) {
                // Reuest was made and server responded with status code outside of 2xx range
                document.getElementById("api-message").innerHTML = err.response.data.status + ": " + err.response.data.message;
              } else if (err.request) {
                // The request was made but no response was received
                document.getElementById("api-message").innerHTML = err.message;
              } else {
                // Something happened in setting up the request that triggered an Error
                document.getElementById("api-message").innerHTML = err.message;
              }
              document.getElementById("api-message").style.color = "red";
        })

}

export const resetUserAccount = (username,token) => {
    var route = baseRoute + username + '/reset-account';
    return axios.post(route, {},
        {
        'headers': {
            'Authorization': token
        }
    })
        .then(res => {
            document.getElementById("api-message").innerHTML = "Reset user account successfully. An invitation email has been sent.";
            document.getElementById("api-message").style.color = "black";
            return res;
        }).catch(err => {
            if (err.response) {
                // Reuest was made and server responded with status code outside of 2xx range
                document.getElementById("api-message").innerHTML = err.response.data.status + ": " + err.response.data.message;
              } else if (err.request) {
                // The request was made but no response was received
                document.getElementById("api-message").innerHTML = err.message;
              } else {
                // Something happened in setting up the request that triggered an Error
                document.getElementById("api-message").innerHTML = err.message;
              }
              document.getElementById("api-message").style.color = "red";
        })

}

export const listUsers = (token) => {
    var route = baseRoute;
    return axios.get(route, {
        'headers': {
            'Authorization': token
        }
    }).then(res => {
        return res.data;
    }).catch(err => {
        if (err.response) {
            // Reuest was made and server responded with status code outside of 2xx range
            document.getElementById("api-message").innerHTML = err.response.data.status + ": " + err.response.data.message;
          } else if (err.request) {
            // The request was made but no response was received
            document.getElementById("api-message").innerHTML = err.message;
          } else {
            // Something happened in setting up the request that triggered an Error
            document.getElementById("api-message").innerHTML = err.message;
          }
          document.getElementById("api-message").style.color = "red";
    })
}

export const getGroupOfUser = (token, username) => {
    var route = baseRoute + 'user-group/' +  username;
    return axios.get(route, {
        'headers': {
            'Authorization': token
        }
    })
        .then(res => {
            return res.data;
        }).catch(err => {
            if (err.response) {
                // Reuest was made and server responded with status code outside of 2xx range
                document.getElementById("api-message").innerHTML = err.response.data.status + ": " + err.response.data.message;
              } else if (err.request) {
                // The request was made but no response was received
                document.getElementById("api-message").innerHTML = err.message;
              } else {
                // Something happened in setting up the request that triggered an Error
                document.getElementById("api-message").innerHTML = err.message;
              }
              document.getElementById("api-message").style.color = "red";
        })
}


export const listUsersGroup = (token) => {
    var route = baseRoute + 'user-group';
    return axios.get(route, {
        'headers': {
            'Authorization': token
        }
    })
        .then(res => {
            return res.data;
        }).catch(err => {
            if (err.response) {
                // Reuest was made and server responded with status code outside of 2xx range
                document.getElementById("api-message").innerHTML = err.response.data.status + ": " + err.response.data.message;
              } else if (err.request) {
                // The request was made but no response was received
                document.getElementById("api-message").innerHTML = err.message;
              } else {
                // Something happened in setting up the request that triggered an Error
                document.getElementById("api-message").innerHTML = err.message;
              }
              document.getElementById("api-message").style.color = "red";
        })

}

export const disableUser = (username,token) => {
    var route = baseRoute + username;
    return axios.delete(route, {
        'headers': {
            'Authorization': token
        }
    })
        .then(res => {
            document.getElementById("api-message").innerHTML = "User disabled successfully.";
            // alert("User disabled successfully.")
            document.getElementById("api-message").style.color = "black";
            return res;
        }).catch(err => {
            if (err.response) {
                // Reuest was made and server responded with status code outside of 2xx range
                document.getElementById("api-message").innerHTML = err.response.data.status + ": " + err.response.data.message;
              } else if (err.request) {
                // The request was made but no response was received
                document.getElementById("api-message").innerHTML = err.message;
              } else {
                // Something happened in setting up the request that triggered an Error
                document.getElementById("api-message").innerHTML = 'Error' + err.message;
              }
              document.getElementById("api-message").style.color = "red";
        })

}



export const updateExistingAttributes = (username,firstName,lastName,token) => {
    var route = baseRoute + username;
    return axios.put(route, 
            { "firstName": firstName,
            "lastName": lastName
        },
        {
            'headers': {
                'Authorization': token
        }
    }).then(res => {
        document.getElementById("api-message").innerHTML = "Updated user first and last name attributes successfully.";
        // alert("Updated user first and last name attributes successfully.");
        document.getElementById("api-message").style.color = "black";
        return res;
    }).catch(err => {
        if (err.response) {
            // Reuest was made and server responded with status code outside of 2xx range
            document.getElementById("api-message").innerHTML = err.response.data.status + ": " + err.response.data.message;
          } else if (err.request) {
            // The request was made but no response was received
            document.getElementById("api-message").innerHTML = err.message;
          } else {
            // Something happened in setting up the request that triggered an Error
            document.getElementById("api-message").innerHTML = 'Error' + err.message;
          }
          document.getElementById("api-message").style.color = "red";
    })

}

export const matchUserGroups = (username,groups,token) => {
    var route = baseRoute + "user-group/" + username;
    return axios.put(route, 
        { "groups": groups}, {
        'headers': {
            'Authorization': token
        }
    })
        .then(res => {
            return res;
        }).catch(err => {
            document.getElementById("api-message").innerHTML = "Failure";
            document.getElementById("api-message").style.color = "red";
            console.log(err.message);
        })
}