import React from 'react';
import './corporateActionGuide.css';

import '@trendmicro/react-sidenav/dist/react-sidenav.css';


class CorporateActionGuide extends React.Component {

  constructor() {
    super();
    this.state = {
      section: "",
      expanded: true,
  }

  }
  render = () => (
    <div className="ca-guide-container">
      <div className="main-help"><h2>CORPORATE ACTION GUIDE</h2>
      </div>
      <div className="guide-body">
          <h1 className="overview">Overview:</h1>
          <p>A corporate action is any activity or event that brings material change to an organisation and impacts its stakeholders, including shareholders, both common and preferred, as well as bondholders. These events are generally approved by the company's&nbsp;<a href="https://www.investopedia.com/articles/analyst/03/111903.asp">board of directors</a>.</p>
          <p><em>Please note where applicable US and Canadian corporate actions will be booked on a T-basis, all other CAs will be booked on a T-1 basis.</em></p>
          <br></br>
          <h2 name="common-types">Common Types of Corporate Actions:</h2>
          <h3>Dividends</h3>
          <p>
            A cash dividend is the most common event to impact a Single Stock CFD holder. 
            It is a distribution of a company’s earnings to its shareholders via cash payment. 
            Clients who have a <strong>long position receive payment</strong> while clients who hold a <strong>short position are charged the dividend</strong>. 
            Long and Short Dividend rates often differ due to With Holding Tax (WHT), which is based upon the domicile of the company paying the dividend. 
            For US domiciled stocks, Withholding Tax is dependent upon your residency and entity structure. 
            In most markets long positions receive a payment that has been taxed while short holders pay the full amount.
          </p>
          <h3>Rights Issues</h3>
          <p>
            When a company implements a rights issue it offers additional or new shares to current shareholders. 
            These existing shareholders are given the right to purchase the shares, usually at a discount, before they are offered to the public.
          </p>
          <p>
            There are two types of rights issues:
          </p>
          <p>
            <strong>Non-renounceable Rights</strong> - These rights allow the shareholder to buy additional shares at a prescribed ratio and price.
          </p>
          <p>
            <strong>Renounceable Rights</strong> - These operate in the same way as the above but the shareholder receiving the rights can sell them on the market.
          </p>
          <h3>Acquisitions</h3>
          <p>
            When a company becomes the target of acquisition, the shareholders may receive offers to tender their shares at a price set by the company making the offer. 
            Shareholders can accept or decline the offer but if enough shareholders agree to the takeover, the event will become mandatory.
          </p>
          <h3>Ticker and Name Changes</h3>
          <p>
            A symbol (or ticker) can change when a company changes its name. 
            This could be due to a re-branding or more commonly a merger/takeover. 
            26 Degrees will move open positions to the new symbols line prior to the market open for that day.
          </p>
          <h3>Stock Splits</h3>
          <p>
            A company may choose to adjust the price of its shares by splitting existing shares into smaller units, 
            or the reverse by reducing the number of shares within the market (also known as a consolidation). 
            During this process Market Capitalisation remains the same and the notional value of each position is unchanged.
          </p>
          <p>
            The main reasons for a stock split are to increase liquidity (more shares to trade) or to decrease the value of each share, making them more affordable to investors. 
            A reverse stock split on the other hand may be used to increase the price of each share (by reducing the shares on issue). 
            This could be to avoid de-listing when the share price gets too low.
          </p>
          <h3>Bonus Issues</h3>
          <p>
            Similar in effect to a Rights Issue. 
            A company may wish to distribute ‘bonus’ shares to shareholders as an alternative or in addition to a dividend. 
            These new shares are issued to the existing shareholders at a set ratio.
          </p>
          <p>
            For example, a company may issue bonus stock at a ratio of 1 for 4 (one bonus share for every four existing shares). 
            The issuance of new shares to the market will dilute the earnings and likely result in a decrease in share price.
          </p>
          <h3>Spin Offs</h3>
          <p>
            A spin-off occurs when a company creates a new company (or demerges an existing company) and lists this separately on the exchange. 
          </p>
          <p>
            Stock Spin Offs will trade on a new symbol that needs to be set up prior to the issue date.
          </p>
          <p>
            Spinoffs can be a complicated corporate action to process. 
            Unlike other corporate actions, the Spin Off stock generally does not have a specific value, initially. 
            The Spin Off may also start trading at a different time of day to the parent company. 
            For the bookings they are best treated like a bonus issue.
          </p>
          <br></br>
          <h2 name="participation">Participation:</h2>

          <p>Corporate Action events are classified as either <strong>mandatory</strong> or <strong>voluntary</strong>.</p>

          <ul>
          <li>If the event is <strong>mandatory</strong>, the shareholder (or Single Stock CFD holder in this case) will not be consulted.</li>
          <li>If the event is <strong>voluntary</strong>, the shareholder (or Single Stock CFD holder in this case) will be consulted regarding their <strong>election</strong> on the corporate action. Rights issue and takeovers are common examples.</li>
          </ul>

          <p>Key to the above is the <strong>ex-date</strong><strong>. </strong>CFD Holders are entitled to most events when they hold a position over the <strong>ex-date. </strong>Positions opened after this date will be trading on an &ldquo;ex entitlement&rdquo; basis.</p>
          <br></br>
          <br></br>
        </div>
    </div>
  )

}

export default CorporateActionGuide;
