import React from 'react';
import './sections.css';
import sessionProfiles from './img/4.2-session-profiles.png';
import symbols from './img/4.2-symbols.png';
import priceChannel from './img/4.2-price-channel.png';
import quoteFilter from './img/4.2-quote-filter.png';

class OZSymbols extends React.Component {
    render = () => (
        <div>            
            <h2>4.0 oneZero Hub Configuration</h2>
            <h2>4.2 Settings - Symbol</h2>
            <ul>
                <li>
                    <p><b>Session Profiles</b></p>
                    <p>- Export CSV</p>
                    <p>- Add (append) the new Session Profiles</p>
                    <p>- Import CSV</p>
                    <p>- Save and Apply Dynamic Settings</p>                    
                    <p>26 Degrees has provided a CSV of these settings which can be imported to oneZero hub. This file is named "InvastGlobal-OZ-SessionProfiles_YYYYMMDD.csv" (see 6.0 Downloads)</p>
                    <img src={sessionProfiles}
                        alt="not found"
                        style={{
                            width: '70vw',
                            maxWidth: '1000px',
                            height:'auto',                                                        
                        }}
                    ></img>
                    <br></br>
                    <br></br>
                    <table style={{
                        width: '70vw',
                        maxWidth: '1000px',
                    }}>
                        <thead>
                            <tr>
                                <th colSpan={7}>Session Profile Parameters</th>
                            </tr>
                            <tr>
                                <th>Name</th><th>Time Zone</th><th>Quotes</th><th>Trades</th><th>Start Time</th><th>End Time</th><th>Days</th>
                            </tr>
                        </thead>
                        <tbody>                        
                            <tr>
                                <td>ASX_AB</td><td>Australia / Sydney</td><td>TRUE</td><td>TRUE</td><td>10 : 00 : 15.000000</td><td>16 : 00 : 00.000000</td><td>Mon, Tue, Wed, Thur, Fri</td>
                            </tr>
                            <tr>
                                <td>ASX_CF</td><td>Australia / Sydney</td><td>TRUE</td><td>TRUE</td><td>10 : 02 : 30.000000</td><td>16 : 00 : 00.000000</td><td>Mon, Tue, Wed, Thur, Fri</td>
                            </tr>
                            <tr>
                                <td>ASX_GM</td><td>Australia / Sydney</td><td>TRUE</td><td>TRUE</td><td>10 : 04 : 45.000000</td><td>16 : 00 : 00.000000</td><td>Mon, Tue, Wed, Thur, Fri</td>
                            </tr>
                            <tr>
                                <td>ASX_NR</td><td>Australia / Sydney</td><td>TRUE</td><td>TRUE</td><td>10 : 07 : 00.000000</td><td>16 : 00 : 00.000000</td><td>Mon, Tue, Wed, Thur, Fri</td>
                            </tr>
                            <tr>
                                <td>ASX_SZ</td><td>Australia / Sydney</td><td>TRUE</td><td>TRUE</td><td>10 : 09 : 15.000000</td><td>16 : 00 : 00.000000</td><td>Mon, Tue, Wed, Thur, Fri</td>
                            </tr>
                            <tr>
                                <td>LSE</td><td>Europe / London</td><td>TRUE</td><td>TRUE</td><td>08 : 00 : 00.000000</td><td>16 : 30 : 00.000000</td><td>Mon, Tue, Wed, Thur, Fri</td>
                            </tr>
                            <tr>
                                <td>PAR</td><td>Europe / Z&uuml;rich</td><td>TRUE</td><td>TRUE</td><td>09 : 00 : 30.000000</td><td>17 : 30 : 00.000000</td><td>Mon, Tue, Wed, Thur, Fri</td>
                            </tr>
                            <tr>
                                <td>ETR</td><td>Europe / Z&uuml;rich</td><td>TRUE</td><td>TRUE</td><td>09 : 00 : 00.000000</td><td>17 : 30 : 00.000000</td><td>Mon, Tue, Wed, Thur, Fri</td>
                            </tr>
                            <tr>
                                <td>MAD</td><td>Europe / Z&uuml;rich</td><td>TRUE</td><td>TRUE</td><td>09 : 00 : 00.000000</td><td>17 : 30 : 00.000000</td><td>Mon, Tue, Wed, Thur, Fri</td>
                            </tr>
                            <tr>
                                <td>DUB</td><td>Europe / London</td><td>TRUE</td><td>TRUE</td><td>08 : 00 : 00.000000</td><td>16 : 28 : 00.000000</td><td>Mon, Tue, Wed, Thur, Fri</td>
                            </tr>
                            <tr>
                                <td>AMS</td><td>Europe / Z&uuml;rich</td><td>TRUE</td><td>TRUE</td><td>09 : 00 : 30.000000</td><td>17 : 30 : 00.000000</td><td>Mon, Tue, Wed, Thur, Fri</td>
                            </tr>
                            <tr>
                                <td>NAS</td><td>America / New York</td><td>TRUE</td><td>TRUE</td><td>09 : 30 : 00.000000</td><td>16 : 00 : 00.000000</td><td>Mon, Tue, Wed, Thur, Fri</td>
                            </tr>
                            <tr>
                                <td>NYS</td><td>America / New York</td><td>TRUE</td><td>TRUE</td><td>09 : 30 : 00.000000</td><td>16 : 00 : 00.000000</td><td>Mon, Tue, Wed, Thur, Fri</td>
                            </tr>
                        </tbody>
                    </table>
                    <p><em>Pre and post market trading are only currently available by phoning 26 Degrees.</em></p>
                </li>               
                <li>
                    <p><b>Symbols</b></p>
                    <p>- Export CSV</p>
                    <p>- Add (append) the new 26 Degrees Symbols</p>
                    <p>- Import CSV</p>
                    <p>- Save and Apply Dynamic Settings</p>
                    <p>All single Stock CFDs must be added as symbols with the 26 Degrees given Core Symbol and a <span className='red-note'>Price Precision Decimal Place in accordance with the 26 Degrees Symbol Specifications.</span></p>
                    <p>Changing the <b>Core Symbol Name</b> or <b>Price Precision Decimal Places</b> is <span className='red-note'>NOT recommended</span> as this will impact pricing & execution in MT5 (unless bespoke Maker Rules are applied by oneZero).</p>
                    <p>26 Degrees has provided a CSV of these settings which can be imported to oneZero hub. This file is named “InvastGlobal-OZ-SymbolSettings_YYYYMMDD.csv” (see 6.0 Downloads)</p>
                    <img src={symbols}
                        alt="not found"
                        style={{
                            width: '70vw',
                            maxWidth: '1000px',
                            height:'auto',                                                        
                        }}
                    ></img>
                    <br></br>
                    <br></br>
                    <table style={{
                        width: '60vw',
                        maxWidth: '600px',
                    }}>
                        <thead>
                            <tr><th colSpan={2}>Symbol Setting Parameters</th></tr>
                            <tr><th>Setting</th><th>Value</th></tr>                            
                        </thead>
                        <tbody>
                            <tr><td>Core Symbol</td><td>Per 26 Degrees Symbol Specifications</td></tr>
                            <tr><td>Taker Symbol</td><td>Leave Blank</td></tr>
                            <tr><td>Base Currency</td><td>Per 26 Degrees Symbol Specifications</td></tr>
                            <tr><td>Denomination</td><td>Same as Base Currency</td></tr>
                            <tr><td>Symbol Group Path</td><td>Per 26 Degrees Symbol Specifications</td></tr>
                            <tr><td>Price Precision Decimal Places</td><td>Per 26 Degrees Symbol Specifications</td></tr>
                            <tr><td>Volume Precision Decimal Places</td><td>0 (or 1 for Fractional Shares)</td></tr>
                            <tr><td>Reference Currency</td><td>Same as Base Currency</td></tr>
                            <tr><td>Credit Multiplier</td><td>1</td></tr>
                            <tr><td>Session Profile Name</td><td>Per 26 Degrees Symbol Specifications</td></tr>
                            <tr><td>Quote Book STP Volume Management</td><td>Deplete By Pending/Filled Volume</td></tr>
                        </tbody>
                    </table>
                </li>
                <li>
                    <p><b>Price Channel</b></p>
                    <p>A price channel musted be created with the TakerID matching the MT5 TakerID created in the Single Stock CFD MT5 Gateway (“MT5_1” in the below example). This price channel must have its own Settings Rules created with the MakerID of your 26 Degrees DMA Maker Adapter.</p>
                    <p>See the below as an example of both the price channel and its corresponding Settings Rule which allows you to receive single stock CFDs pricing through your oneZero MT5 Gateway. oneZero will assist in this process.</p>
                    <img src={priceChannel}
                        alt="not found"
                        style={{
                            width: '70vw',
                            maxWidth: '1000px',
                            height:'auto',                                                        
                        }}
                    ></img>
                </li>
                <li>
                    <p><b>Quote Filtering Settings</b></p>
                    <p>A minimum spread filter of 0 must be applied to the 26 Degrees DMA Maker Adapter. This prevents inverted quote ticks from passing through the hub. As per below.</p>
                    <img src={quoteFilter}
                        alt="not found"
                        style={{
                            width: '70vw',
                            maxWidth: '1000px',
                            height:'auto',                                                        
                        }}
                    ></img>
                </li>
            </ul>
        </div>
    )
}

export default OZSymbols;