import React from 'react';
import PropTypes from 'prop-types';

const LogoutButton = ({ onClick }) => (
  <div style={
    {
    height: "100%",
    width: "100%",
    display: "inline-block"
   }} >
  <p style={
    {
    margin: 0
   }} className="zero-buffer" onClick={onClick}>Log out</p>
  </div>
);
LogoutButton.propTypes = {
  onClick: PropTypes.func,
};
export default LogoutButton;
