import React from 'react';
import { getDocument } from '../../logic/queryAWSAPI.js';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { getChangelog } from '../../logic/queryAPI.js'
import Loader from '../../Loader.jsx';

class Downloads extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            rawData: [],
            data: [],
            loadingData: false,
            vw: null,
            vh: null
        }
    }


    render() {

        const { data } = this.state;

        return (
        <div>
            <h2>Downloads</h2>
            <div className="dl-section">
                <button className="download-button" onClick={() => this.download("InvastGlobal-MT5-SingleStocks.zip", this.props.token)}>MT5 Single Stocks</button>
                <div className="dl-blurb">
                    A complete configuration file (json) of 26 Degrees Single Stocks ready to be imported into MT5 (compressed in zip).
                </div>
            </div>
            <div className="dl-section">
                <button className="download-button" onClick={() => this.download("InvastGlobal-OZ-SessionProfiles.csv", this.props.token)}>oneZero Session Profiles</button>
                <div className="dl-blurb">
                    All the session profiles required to open and close the core symbols within oneZero.
                </div>
            </div>
            <div className="dl-section">
                <button className="download-button" onClick={() => this.download("InvastGlobal-OZ-SymbolSettings.csv",this.props.token)}>oneZero Symbol Settings</button>
                <div className="dl-blurb">
                    A complete config file of the 26 Degrees Single Stocks Settings ready to be imported into oneZero.
                </div>
            </div>
            <h2><br></br>Changelog</h2>
            <div className="wrapper">
            <div className="searchChangelog">
                <input type="text" className="searchTerm" id="searchTerm" placeholder="Search by file or message" onChange={(e) => this.searchFiltered(e)} />
                <button className="searchButton" >
                    <i className="fa fa-search"></i>
                </button>
            </div>
            <div className="tableHolderChangelog">
                    <ReactTable
                        data={data}
                        loading={this.state.loadingData}
                        LoadingComponent={Loader}
                        columns={[
                            {
                                Cell: (<button>Edit</button>),
                                Header: "File Changelog",
                                columns: [
                                    {
                                        Header: "File",
                                        accessor: "displayname",
                                        width: 10 * this.state.vw,
                                    },
                                    {
                                        Header: "Message",
                                        accessor: "message",
                                        width: 30 * this.state.vw,
                                        Cell: (rowInfo) => this.changelogMessage(rowInfo),
                                    },
                                    {
                                        Header: "Update Time (UTC)",
                                        accessor: "updated",
                                        width: 10 * this.state.vw,
                                    }
                                ]
                            }
                        ]}

                        defaultPageSize={20}
                        defaultSorted={[
                            {
                              id: "updated",
                              desc: true
                            }
                          ]}
                        className="-striped -highlight summary"
                        getTheadProps={(state, rowInfo, column) => this.headerProps(state, rowInfo, column)}
                        getTheadGroupProps={(state, rowInfo, column) => this.headerProps(state, rowInfo, column)}
                    />
                    
            </div>
        </div>
        </div>
    )}

    download(filename, token) {
        getDocument(filename, token).then(res => {
            var url=res.data.documentUrl;
            var link = document.createElement("a");
            link.download = filename;
            link.href = url;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            link = null;
        })
    }

    changelogMessage(row) {
        return row.original.message.split('\n').map(x => <div>{x}</div>)
    }
    
    columnBorderProps(state, rowInfo) {
        return {
            style: {
                borderRight: '7px solid rgba(0,0,0,0.07)',
                borderLeft: '1px solid rgba(0,0,0,0.07)',
            }
        }
    }


    columnProps(state, rowInfo) {
        return {
            style: {
                borderRight: '1px solid rgba(0,0,0,0.07)',
                borderLeft: '1px solid rgba(0,0,0,0.07)',
            }
        }
    }

    rowProps(state, rowInfo, column) {
        return {
            style: {
                border: '1px solid rgba(0,0,0,0.07)',
            }
        }
    }

    // table setup helper
    headerProps(state, rowInfo, column) {
        return {
            style: {
                background: '#004b83',
                boxShadow: 'none',
                whiteSpace: 'pre-line !important',
                wordWrap: 'break-word',
                // border: '1px solid rgba(0,0,0,0.07)'              
            }
        }
    }

    groupHeaderProps(state, rowInfo, column) {
        return {
            style: {
                background: '#004b83',
                boxShadow: 'none',
                
                // borderLeft: '7px solid rgba(0,0,0,0.07)',
                // borderRight: '7px solid rgba(0,0,0,0.07)'
            }
        }
    }

    searchFiltered = (e) => {
        var searchTerm = document.getElementById("searchTerm").value.toLowerCase();
        var searchResults = []
        var rawData = this.state.rawData;
        // console.log(rawData[1].Name);
        for (var i = 0; i < this.state.rawData.length; i++) {
            if (rawData[i].displayname === undefined || rawData[i].message === undefined) {
                break;
            }
            if (rawData[i].displayname.toLowerCase().includes(searchTerm) || rawData[i].message.toLowerCase().includes(searchTerm)) {
                searchResults.push(rawData[i])
            }
        }
        this.setState({
            data: searchResults,
        })
    }

    componentDidMount() {
        this.setState({
            loadingData: true
        });
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
        this.getData();
    }

    getData() {

        getChangelog(this.props.token).then(res => {
            this.setState({
                data: res.data,
                rawData: res.data,
                loadingData: false
            });
        })
    }

    componentWillUnmount() {
        clearInterval(this.interval);
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions = () => {
        this.setState({ vw: window.innerWidth / 100, vh: window.innerHeight / 100});
      }


}

export default Downloads;