import React from 'react';
import './sections.css';

class OZMargins extends React.Component {
    render = () => (
        <div>            
            <h2>4.0 oneZero Hub Configuration</h2>
            <h2>Margins</h2>
            <p>Please refer to the <a href='https://portal.26degreesglobalmarkets.com/single-stocks/product-info'>standard margin table</a> for updated margin requirements.</p>
        </div>
    )
}

export default OZMargins;