import React from 'react';
import PropTypes from 'prop-types';
import './loginPage.css'
import {Link} from 'react-router-dom';

class LoginForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      email: props.email,
      username: props.username,
      password: '',
    };
  }

  onSubmit = (event) => {
    event.preventDefault();
    this.props.onSubmit(this.state.username, this.state.password);
  }

  changeUsername = (event) => {
    this.setState({ username: event.target.value });
  }

  changePassword = (event) => {
    this.setState({ password: event.target.value });
  }

  componentWillUnmount = () => {
    this.props.clearCache();
  }

  render = () => (
    <div id="login-cont">
      <div className="banner">
          <h2 className="headerText">SINGLE STOCK CFD BROKER PORTAL</h2>
      </div>
      <div className="login-main">
      <link href="https://fonts.googleapis.com/css?family=Inter" rel="stylesheet" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
        <p className="sign">Sign in</p>
        <form onSubmit={this.onSubmit} className="form1">
        <div className="error">{this.props.error}</div>
        <div className="email">{this.state.email}</div>
          <input id="username" className='un' type='text'  placeholder="Username" value={this.state.username} onChange={this.changeUsername} required />
          <input id="pass" className='pass'  placeholder="Password" onChange={this.changePassword} type="password" required />
          <input className='submit' type='submit' value='LOGIN' />
          <p className="forgot"><Link to="/reset">Forgot Password?</Link></p>
          </form>
    </div>
    </div>
  )

  componentDidMount() {
  }
}
LoginForm.propTypes = {
  onSubmit: PropTypes.func,
  clearCache: PropTypes.func,
  username: PropTypes.string,
  error: PropTypes.string,
  email: PropTypes.string,
};

export default LoginForm;
