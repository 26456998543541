import React from 'react';
import ReactDOM from 'react-dom';
import { Provider} from 'react-redux';
import { combineReducers, createStore } from 'redux';
import { setupCognito, cognito } from 'react-cognito';
import App from './App';
import './index.css';

import config from './config.json';

const reducers = combineReducers({
    cognito,
});

let store = createStore(reducers, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

// config.group = 'admins'; // Uncomment this to require users to be in a group 'admins'
let host = window.location.hostname
if (host.includes("dev") || host.includes("localhost")) {
  setupCognito(store, config.dev);
} else if (host.includes("uat")) {
  setupCognito(store, config.uat);
} else {
  setupCognito(store, config.prod);
}

ReactDOM.render(
  <Provider store={store}>
    <App/>
  </Provider>, 
  document.getElementById('root'));
